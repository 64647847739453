import React, { useEffect, useState } from "react";
import "./OriginalCoursesCard.css";
import { Link } from "react-router-dom";
import { HiLockClosed } from "react-icons/hi2";

function OriginalCoursesCard({
  smallCard,
  image,
  typeCourse,
  name,
  teacher,
  redirectTo,
}) {
  const marginCard = {
    marginLeft: "3px",
    marginRight: "3px",
    transitionTimingFunction: "ease-out",
    transitionProperty: "transform",
    transitionDuration: "300ms",
  };

  return (
    <div className="flex w-full justify-center">
      <section className="w-full flex flex-col h-auto">
        <Link
          to={redirectTo}
          style={marginCard} reloadDocument
          className={`my-3 flex flex-col relative h-auto justify-end z-10 hover:scale-[102%] rounded-[0.8rem] max-lg:scale-[102%]`}
        >
          <div className="w-full flex flex-col justify-end">
            <div className="w-full rounded-[0.8rem] flex items-end">
              {/* Adquire Screen */}
              {/* {redirectTo.includes("/adquire") && ( */}
              {redirectTo.includes("#") && (
                <div className="z-[1] flex absolute w-full h-full top-0 rounded-md justify-end items-start pt-5 pr-5">
                  <div className="bg-[#3a3b41cc] h-7 w-7 rounded-full flex items-center justify-center text-[#fff]">
                    <HiLockClosed className="w-3 h-3" />
                  </div>
                </div>
              )}
              <img
                src={image}
                alt="Imagem do Curso"
                style={{ minHeight: "auto" }}
                className={`object-cover w-full h-[512px] min-h-[512px] rounded-[0.8rem] hover:min-lg:border-white p-1 ${
                  smallCard
                    ? "max-lg:h-[77.74vw] max-lg:w-[43.73vw]"
                    : "max-lg:h-[105.72vw] max-lg:w-[59.47vw]"
                }`}
              />
            </div>
            <div className="w-full opacity-100 px-3 pt-3 flex flex-col gap-1">
              <p className="w-full text-sm select-none leading-tight font-semibold text-themeColor max-lg:text-[0.75rem]">
                {typeCourse}
              </p>
              <p className="max-lg:leading-tight max-lg:text-sm max-lg:text-ellipsis max-lg:overflow-hidden">
                {name}
              </p>
              {teacher && (
                <p className="w-full text-xs text-textSecondary select-none leading-tight font-normal">
                  Ministrado por {teacher}
                </p>
              )}
            </div>
          </div>
        </Link>
      </section>
    </div>
  );
}

export default OriginalCoursesCard;
