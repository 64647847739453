import React from "react";
import { HiMiniCheck } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { certifier } from "../../mocks/certifier";

export default function ClassVideoCard({
  content,
  index,
  courseAlias,
  disciplineId,
}) {
  
  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <Link
      reloadDocument // added by Gabriel, to fix the bad start page loading on mobile
      onClick={handleScrollToTop}
      to={`/course/${courseAlias}/${disciplineId}/${content.classId}`}
      id={content.type + "-" + index}
      className="flex justify-center items-center text-left w-full h-fit p-1 max-lg:px-0"
    >
      <div className="w-[50%] h-fit mr-4 relative rounded-md overflow-hidden">
        <img
          src={`${
            content.type === "player"
              ? content.thumb
              : `/images/collegeContent/${content.type}.webp`
          }`}
          className="min-h-[4.0625rem]"
          alt="Capa da aula"
        />
        {content.progress && content.progress !== 0 ? (
          <div className="bottom-0 left-0 absolute w-full h-1 bg-[#2a2d31]/70">
            <div
              className="h-full bg-themeColor"
              style={{ width: `${content.progress}%` }}
            />
          </div>
        ) : null}
        {content.concluded ? (
          <div className="top-0 right-0 flex absolute w-full h-full rounded-md justify-end items-start pt-1 pr-1">
            <div className="bg-[#3a3b41cc] h-[16px] w-[16px] rounded-full flex items-center justify-center text-[#fff]">
              <HiMiniCheck className="w-[10px] h-[10px]" />
            </div>
          </div>
        ) : null}
      </div>
      <div className="w-[60%] flex flex-col justify-center h-full relative gap-y-1">
        <span className="text-[0.75rem] text-textPrimary font-medium leading-tight overflow-hidden overflow-ellipsis line-clamp-3">
          {index + 1}
          {". "}
          {content.name}
        </span>
      </div>
    </Link>
  );
}
