import React, { useEffect, useState } from "react";
import OriginalCoursesCard from "../OriginalCourses/OriginalCoursesCard/OriginalCoursesCard";

function ListCourses({
  enableEnterAllCourses,
  allCourses,
  // headerTitle,
  // headerSubtitle,
}) {
  const [displayCourses, setDisplayCourses] = useState([]);
  const [quantity, setQuantity] = useState(8);
  useEffect(() => {
    setDisplayCourses(allCourses.slice(0, 8));
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop <
        document.documentElement.offsetHeight - 5
      )
        return;
      setQuantity((quantity) => {
        const newQuantity = quantity + 8;
        setDisplayCourses(allCourses.slice(0, newQuantity));
        return newQuantity;
      });
    };
    const timer = setTimeout(() => {
      window.addEventListener("scroll", handleScroll);
    }, 100);
    return () => {
      clearTimeout(timer);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [allCourses]);

  return (
    <section className="w-full flex flex-col items-center rounded-md pt-8">
      <section
        id="courses-scroll"
        className="max-w-[1170px] w-full flex flex-col items-center my-8 max-lg:my-3"
      >
        <div className="w-[102%] -ml-1 grid grid-cols-4 gap-2 gap-y-4 max-lg:gap-0 max-lg:w-full max-lg:ml-0 max-lg:m-0 max-lg:grid-cols-2 max-lg:px-[5vw]">
          {typeof displayCourses === "object" &&
            displayCourses.map((course, index) => (
              <OriginalCoursesCard
                smallCard
                key={index}
                image={course.image}
                typeCourse={course.typeCourse}
                name={course.name}
                teacher={course.teacher}
                redirectTo={
                  enableEnterAllCourses || course.unlockCourse
                    ? `/course/${course.alias}`
                    // Adquire Screen
                    // : `/adquire/${course.alias}`
                    : `#`
                }
              />
            ))}
        </div>
      </section>
    </section>
  );
}

export default ListCourses;
