import { useEffect } from "react";
import { certifier } from "../../mocks/certifier";

function LoadingSection({ isLoading, hideScroll = false }) {
  useEffect(() => {
    if (isLoading && !hideScroll) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [isLoading]);

  return (
    <section
      className={`${
        !isLoading && "opacity-0 pointer-events-none"
      } select-none transition-all duration-300 fixed top-0 left-0 z-[200] w-screen h-screen overflow-hidden bg-backgroundOne flex flex-col gap-7 justify-center items-center`}
    >
      <img
        src={`/images/logos/logo-${certifier.colors.basics.primary_darkTheme ? "light" : "dark"}.webp`}
        alt="Logo"
        className="max-w-[58px] w-fit h-[60px]"
      />
      <div className="flex justify-center items-center gap-2">
        {Array.from({ length: 3 }, (_, i) => (
          <div
            key={i}
            className="w-[12px] h-[12px] rounded-full animate-loading"
            style={{
              backgroundColor: `${
                certifier
                  .colors.basics.primary
              }`,
              animationDelay: `${i * 0.3}s`,
            }}
          />
        ))}
        <style jsx>{`
          @keyframes pulse {
            0% {
              transform: scale(1);
              opacity: 0.1;
            }
            50% {
              transform: scale(1.1);
              opacity: 100;
            }
            100% {
              transform: scale(1);
              opacity: 0.1;
            }
          }
          .animate-loading {
            animation: pulse 2s ease-in-out infinite;
          }
        `}</style>
      </div>
    </section>
  );
}

export default LoadingSection;
