import axios from "axios";
import moment from "moment";
import "moment/locale/pt-br";
import { CryptoService } from "../cryptoService/crypto.service";
import * as Sentry from "@sentry/react";

export const getPreviewDeclarations = async ({ sessionId, matriculationId, courseId, type, status }) => {
  try {
    const post_data = await CryptoService({ "matriculationId": matriculationId, "courseId": courseId, "type": type, "status": status, exp: moment().add(2, "hours").unix() }, "encrypt-jwt");
    const auth = await CryptoService({ "sessionId": sessionId, exp: moment().add(2, "hours").unix() }, "encrypt-jwt");
    const body = { token: post_data };
    const config = {
      headers: {
        auth: `${auth}`,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/preview/declarations`,
      body,
      config
    );
    if (response.status !== 200) {
      return { status: response.status };
    }

    const responseData = await CryptoService(
      response.data.data,
      "decrypt-json"
    );
    return {
      data: responseData,
      status: response.status,
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);
localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response ? err.response.data.code : 500 };
  }
};

export const getStepsIssueDeclarations = async ({
  studentInfos,
  selectedCourseOption,
}) => {
  try {
    const post_data = await CryptoService(
      {
        sessionId: studentInfos.sessionId,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );
    const cryptoInfos = await CryptoService(
      {
        matriculationId: selectedCourseOption.matriculationId,
        courseId: selectedCourseOption.courseId,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );
    const body = { token: cryptoInfos };
    const config = {
      headers: {
        auth: `${post_data}`,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/stepsIssueDeclarations`,
      body,
      config
    );
    if (response.status !== 200) {
      return { status: response.status };
    }

    const responseData = await CryptoService(
      response.data.data,
      "decrypt-json"
    );

    return {
      data: responseData,
      status: response.status,
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);
localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response ? err.response.data.code : 500 };
  }
};

export const downloadDeclarations = async ({ sessionId, matriculationId, courseId, type }) => {
  try {
    const url = `${process.env.REACT_APP_API_ADDRESS}/download/declarations`;

    const post_data = await CryptoService({ "matriculationId": matriculationId, "courseId": courseId, "type": type, exp: moment().add(2, "hours").unix() }, "encrypt-jwt");
    const auth = await CryptoService({ "sessionId": sessionId, exp: moment().add(2, "hours").unix() }, "encrypt-jwt");
    const options = { responseType: "blob", headers: { auth: auth } };
    const response = await axios.post(url, { token: post_data }, options);
    return { data: response.data, status: response.status };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);
localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response ? err.response.data.code : 500 };
  }
};
