import "./CertificatePreview.css";
import moment from "moment";
import { brazilianStatesSelect } from "../../services/selectService/brazilianStates";

export default function CertCursosLivresFront({
  formData,
  selectedCourse,
  certificateContent
}) {
  
  const date = new Date();
  const actualFullDate = String(
    date.toLocaleDateString("pt-BR", {
      day: "numeric",
      month: "long",
      year: "numeric",
      timeZone: "America/Sao_Paulo"
    })
  );

  const formatUf = (uf) => {
    const brazilianStates = brazilianStatesSelect;

    const state = brazilianStates.find((state) => state.uf === uf);
    return state ? state.name : formData.othersBirthState;
  };

  if (formData)
    return (
      <div className="relative flex flex-col overflow-hidden">
        <img
          src="/images/assets/frontCertificado-Libano.webp"
          alt="Frente do Certificado"
          className="w-full h-full select-none"
        />
        <div className="absolute top-0 left-0 w-full h-full">
          <div className="flex flex-col pl-[18.2%] pr-[19.5%] pt-[24%] gap-y-[1rem]">
            <p className="text-black text-justify text-[43.88%] select-none max-mc:text-[5px]">
              Certificamos que <b>{String(formData.name).toUpperCase()}</b>, de
              nacionalidade <b>{String(formData.nationality).toUpperCase()}</b>,
              portador(a) da célula de identidade n°{" "}
              <b>{String(formData.rg).toUpperCase()}</b>, expedido por{" "}
              <b>
                {String(formData.organIssuing).toUpperCase()}/
                {String(formData.uf)}
              </b>
              , nascido(a) em{" "}
              <b>
                {String(
                  moment(String(formData.birthDate)).format(
                    "DD [de] MMMM [de] YYYY"
                  )
                ).toUpperCase()}
              </b>
              , no Estado <b>{String(formatUf(formData.uf)).toUpperCase()}</b>,
              concluiu com êxito o Curso de Extensão em{" "}
              <b>{String(selectedCourse.label).toUpperCase()}</b>. O curso teve uma carga horária total de{" "}
              <b>{certificateContent.table.courseWorkload} HORAS</b> e foi
              concluído em <b>{String(actualFullDate).toUpperCase()}.</b>
            </p>
            <p className="text-black text-justify text-[43.88%] select-none max-mc:text-[5px]">
              Araxá, {actualFullDate}.
            </p>
          </div>
        </div>
      </div>
    );
}
