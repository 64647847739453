import React, { useEffect, useState } from "react";
import "./VideoSlider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Autoplay,
  Parallax,
  EffectCreative,
} from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/parallax";
import VideoClipSlide from "./VideoClipSlide";
import { certifier } from "../../mocks/certifier";

function VideoSlider() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    setCurrentIndex(swiper.realIndex);
  };

  return (
    <div className="w-full">
      {certifier.portal.banners && certifier.portal.banners.length ? (
      <section className="swiper-cards-progress flex justify-between items-center gap-12 pb-10 max-lg:pb-0 select-none">
        <Swiper
          className={`bg-[#111216] w-full`}
          // navigation={true}
          navigation={false}
          slidesPerView={1}
          simulateTouch={false}
          // loop={true} // Disabled Loop to stop warning
          loop={certifier.portal.banners && certifier.portal.banners.length > 1 ? true : false}
          pagination={{ clickable: true }}
          speed={1000}
          autoplay={{ delay: 3000, pauseOnMouseEnter: true }}
          parallax={true}
          style={{ transitionTimingFunction: "ease-in-out" }}
          onSlideChange={handleSlideChange}
          // modules={[Navigation, Pagination, Autoplay, Parallax, EffectCreative]}
          modules={[Pagination, Autoplay, Parallax, EffectCreative]}
          effect="creative"
          creativeEffect={{
            prev: {
              translate: ["-40%", 0, 0],
            },
            next: {
              translate: ["100%", 0, 0],
            },
          }}
        >
          {certifier.portal.banners.map((item, index) => (
            <SwiperSlide key={index} className="relative max-h-[85vh]">
              <VideoClipSlide
                redirectTo={item.redirectTo}
                item={item}
                currentIndex={currentIndex}
                blankPageButton
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
      ):(
        <section className="py-16"/>
      )}
    </div>
  );
}

export default VideoSlider;
