import React, { useEffect, useRef, useState } from "react";
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";
import { HiOutlineBookmark, HiPlay } from "react-icons/hi2";
import { Link } from "react-router-dom";
import HomePandaVideoPlayer from "../VideoPlayers/HomePandaVideoPlayer";
import "./VideoSlider.css";
import { Toastify } from "../Toastify/Toastify";
import DiscoveryCourseWithAI from "../DiscoveryCourse/DiscoveryCourseInput/DiscoveryCourseWithAI";
import { certifier } from "../../mocks/certifier";

export default function VideoClipSlide({
  adquireScreen,
  limitedMode,
  item,
  currentIndex,
  redirectTo,
  continueWatching,
  blankPageButton,
  courses,
}) {
  const [isImageVisible, setImageVisibility] = useState(true);
  const [textIndex, setTextIndex] = useState(0);
  const containerRef = useRef(null);
  const indexLimit = 1;
  useEffect(() => {
    const timer = setTimeout(() => {
      if (textIndex >= indexLimit) {
        setTextIndex(0);
      } else {
        setTextIndex(textIndex + 1);
      }
    }, 5000);
    return () => clearTimeout(timer);
  }, [textIndex]);

  useEffect(() => {
    if (typeof currentIndex === "undefined") {
      const timer = setTimeout(() => {
        setImageVisibility(false);
      }, 1500);
      return;
    }
    if (currentIndex === item.id) {
      const timer = setTimeout(() => {
        setImageVisibility(false);
      }, 1500);
      return () => clearTimeout(timer);
    } else {
      setImageVisibility(true);
    }
  }, [currentIndex, item.id]);

  useEffect(() => {
    function handleResize() {
      if (containerRef.current === undefined || containerRef.current === null)
        return;
      if (window.innerWidth <= 1024) {
        const screenHeight = window.innerHeight;
        const desiredHeight = screenHeight * 0.85;
        const desiredWidth = desiredHeight * (16 / 9);
        containerRef.current.style.height = `${desiredHeight}px`;
        containerRef.current.style.width = `${desiredWidth}px`;
        containerRef.current.style.left = "50%";
        containerRef.current.style.transform = "translateX(-50%)";
      } else {
        const desiredHeight = window.innerWidth * (9 / 16);
        containerRef.current.style.width = `${window.innerWidth}px`;
        containerRef.current.style.height = `${desiredHeight}px`;
      }
    }

    if (containerRef && limitedMode) {
      window.addEventListener("resize", handleResize);
      handleResize();
    }

    return () => window.removeEventListener("resize", handleResize);
  }, [limitedMode]);

  const handleSaveCourse = () => {
    Toastify("success", "Você também será notificado em futuras promoções!");
  };

  const handleClick = () => {
    window.open(item.buttonLink, "_blank");
  };

  return (
    <div>
      <div className="relative overflow-hidden">
        <div className="bg-gradient-to-t from-backgroundOne-via-72 z-[2] w-full h-full absolute top-0 left-0" />
        <div className="w-screen h-[85vh]" />
        {item.video ? (
          <>
            <img
              src={item.imageThumb}
              alt={`Conteúdo ${item.id}`}
              className={`z-[1] absolute top-0 left-0 w-full h-auto transition-all duration-500 max-lg:h-full max-lg:object-cover ${
                !isImageVisible && !String(item.video).includes("scorm")
                  ? "opacity-0"
                  : "opacity-100"
              }`}
            />
            {String(item.video).includes("panda") ? (
              <HomePandaVideoPlayer
                className="absolute top-0 left-0 w-full h-[calc(100vw*(9/16))] max-lg:h-[85vh] min-w-screen"
                backgroundVideo
                autoPlay
                src={item.video}
              />
            ) : (
              !String(item.video).includes("scorm") && (
                <div ref={containerRef} className="absolute top-0 left-0">
                  <video
                    ref={containerRef}
                    src={item.video}
                    autoPlay
                    muted
                    className="w-full h-full"
                  />
                </div>
              )
            )}
          </>
        ) : (
          <img
            src={item.imageThumb}
            alt={`Conteúdo ${item.id}`}
            className="absolute top-0 left-0 w-full h-auto"
            data-swiper-parallax={currentIndex === item.id ? "0%" : "50%"}
          />
        )}
        <div
          className="absolute top-0 h-full w-[40vw]"
          data-swiper-parallax-opacity="0"
        />
        <div className="absolute top-0 left-0 text-textPrimary flex flex-col items-center justify-end h-full w-full z-[20] max-lg:px-[5vw]">
          <div className="w-full max-w-[1170px] pb-[3.75rem] max-lg:pb-[3rem]">
            <div
              className="w-[40%] flex flex-col gap-y-5 max-lg:w-full"
              data-swiper-parallax-opacity="0"
              data-swiper-parallax="100%"
            >
              <div className="flex flex-col gap-y-1 max-lg:items-center">
                <h1 className="my-2 text-3xl text-textPrimary font-bold leading-[1.35] max-lg:leading-tight max-lg:text-2xl max-lg:text-center">
                  {item.title}
                </h1>
                {adquireScreen && (
                  <Link
                    // to={`${certifier.site.url.mainWebsite}/pos-graduacao/${adquireScreen.area[0].alias}/curso/${
                    to={`/pos-graduacao/${adquireScreen.area[0].alias}/curso/${
                      adquireScreen.alias
                    }`}
                    target="_blank"
                    className="flex items-center gap-3 mb-6 mt-3 w-fit h-fit group"
                  >
                    <div className="flex relative w-8 h-8">
                      <div className="shine-effect absolute bg-backgroundTwo rounded-md flex w-8 h-8 items-center justify-center group-hover:brightness-125 cursor-pointer transition-all duration-300">
                        <img
                          src={`/images/logos/logo-${certifier.colors.basics.primary_darkTheme ? "light" : "dark"}.webp`}
                          className="w-5 h-5 transition-all duration-300"
                        />
                      </div>
                    </div>
                    <div className="flex items-center min-w-[220px] h-5 relative overflow-hidden">
                      <div
                        className={`absolute flex items-center gap-2 w-fit transition-all duration-300 group-hover:ml-0.5 ${
                          textIndex === 0
                            ? "translate-y-0 opacity-100"
                            : "translate-y-full opacity-0"
                        }`}
                      >
                        <p
                          className={`text-backgroundOne text-sm max-lg:text-center`}
                        >
                          {adquireScreen.typeCourse === "posGraduacao"
                            ? "Pós-graduação"
                            : "Cursos Livres"}
                        </p>
                        <p
                          className={`text-backgroundOne text-sm max-lg:text-center`}
                        >
                          •
                        </p>
                        <p
                          className={`text-backgroundOne text-sm max-lg:text-center`}
                        >
                          <b>{adquireScreen.minCourseTime} meses</b>
                        </p>
                      </div>
                      <div
                        className={`absolute flex items-center gap-2 w-fit transition-all duration-300 group-hover:ml-0.5 ${
                          textIndex === 1
                            ? "translate-y-0 opacity-100"
                            : "translate-y-full opacity-0"
                        }`}
                      >
                        <p
                          className={`text-backgroundOne text-sm max-lg:text-center`}
                        >
                          <b>Nota 5 no MEC</b>
                        </p>
                        <p
                          className={`text-backgroundOne text-sm max-lg:text-center`}
                        >
                          •
                        </p>
                        <p
                          className={`text-backgroundOne text-sm max-lg:text-center`}
                        >
                          {adquireScreen.workload} horas
                        </p>
                      </div>
                    </div>
                  </Link>
                )}
                <p
                  className={`text-textPrimary text-sm max-lg:text-center ${
                    limitedMode && !adquireScreen && "line-clamp-2"
                  }`}
                >
                  {item.description}
                </p>
              </div>
              <div className="flex items-center gap-6">
                {redirectTo && redirectTo !== "" ? (
                  <Link
                    className="max-lg:flex max-lg:w-full max-lg:justify-center"
                    to={`${redirectTo}`}
                    target={blankPageButton ? "_blank" : "_self"}
                    rel="noreferrer"
                  >
                    <ButtonPrimary
                      optionalBackground={
                        certifier
                          .colors.buttons.buttonTertiary
                      }
                      optionalTextColor={
                        certifier
                          .colors.buttons.buttonTertiary_darkTheme ? "#fff" : "#000"
                      }
                      textButton={`${
                        continueWatching
                          ? "Continuar assistindo"
                          : item.buttonText
                          ? item.buttonText
                          : "Assistir agora"
                      }`}
                      desktopWidth="245px"
                      mobileWidth="60%"
                      optionalMovingIcon={
                        !adquireScreen ? <HiPlay /> : undefined
                      }
                    />
                  </Link>
                ) : (
                  <ButtonPrimary
                    optionalBackground={
                      certifier.colors
                        .buttons.buttonTertiary
                    }
                    optionalTextColor={
                      certifier
                          .colors.buttons.buttonTertiary_darkTheme ? "#fff" : "#000"
                    }
                    textButton={`${
                      item.buttonText ? item.buttonText : "Assistir agora"
                    }`}
                    desktopWidth="245px"
                    mobileWidth="100%"
                    verifyOnClick={item.buttonLink ? handleClick : undefined}
                    disabled={item.buttonDisabled}
                  />
                )}
                {adquireScreen && (
                  <ButtonPrimary
                  optionalBackground={certifier.colors.buttons.buttonTertiary}
                  optionalTextColor={certifier
                    .colors.buttons.buttonTertiary_darkTheme ? "#fff" : "#000"}
                    textButton={`Salvar curso`}
                    disableHorizontalMovement
                    desktopWidth="200px"
                    mobileWidth="100%"
                    optionalIcon={<HiOutlineBookmark />}
                    verifyOnClick={handleSaveCourse}
                    reverseItems
                  />
                )}
              </div>
              {adquireScreen && <DiscoveryCourseWithAI courses={courses} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
