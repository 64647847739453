import React, { useEffect, useRef } from "react";

const HomePandaVideoPlayer = ({
  autoPlay,
  sessionId,
  disciplineId,
  classId,
  courseId,
  className,
  src,
  time,
  backgroundVideo,
}) => {
  let player;

  const containerRef = useRef(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://player.pandavideo.com.br/api.v2.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      let videoStatus = "";
      window.pandascripttag = window.pandascripttag || [];
      window.pandascripttag.push(function () {
        player = new window.PandaPlayer(`panda-${src.split("v=")[1]}`, {
          onReady: () => {
            player.onEvent(function ({ message }) {
              if (message === "panda_pause") {
                videoStatus = "paused";
              } else if (message === "panda_play") {
                videoStatus = "playing";
              }
            });
          },
          onError: (event) => {
            console.error("Player onError", event);
          },
        });
      });
    };

    return () => {
     // document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", adjustVideoDimensions);

    adjustVideoDimensions();

    return () => window.removeEventListener("resize", adjustVideoDimensions);
  }, [backgroundVideo]);

  const adjustVideoDimensions = () => {
    if (!containerRef.current) return;

    // if (window.innerWidth <= 1024 && backgroundVideo) {
    //   const screenHeight = window.innerHeight;
    //   const desiredHeight = screenHeight * 0.85;
    //   const desiredWidth = desiredHeight * (16 / 9);
    //   if (desiredWidth < window.innerWidth) {
    //     const containerWidth = containerRef.current.offsetWidth;
    //     const newHeight = containerWidth * (9 / 16);
    //     containerRef.current.style.height = `${newHeight}px`;
    //   } else {
    //     containerRef.current.style.height = `${desiredHeight}px`;
    //     containerRef.current.style.width = `${desiredWidth}px`;
    //     containerRef.current.style.marginLeft = `-${desiredWidth / 4}px`;
    //   }
    // } else {
    //   const containerWidth = containerRef.current.offsetWidth;
    //   const newHeight = containerWidth * (9 / 16);
    //   containerRef.current.style.height = `${newHeight}px`;
    // }
    if (window.innerWidth <= 1024 && backgroundVideo) {
      const screenHeight = window.innerHeight;
      const desiredHeight = screenHeight * 0.85;
      const desiredWidth = desiredHeight * (16 / 9);
      containerRef.current.style.height = `${desiredHeight}px`;
      containerRef.current.style.width = `${desiredWidth}px`;
      // containerRef.current.style.marginLeft = `-${desiredWidth / 2}px`;
      containerRef.current.style.left = "50%";
      containerRef.current.style.transform = "translateX(-50%)";
    } else {
      const desiredHeight = window.innerWidth * (9 / 16);
      containerRef.current.style.width = `${window.innerWidth}px`;
      containerRef.current.style.height = `${desiredHeight}px`;
    }
  };

  return (
    <div
      ref={containerRef}
      id="video-player-container"
      className={className ?? "w-full h-full"}
    >
      <iframe
        id={`panda-${src.split("v=")[1]}`}
        src={`${src}&preload=true&startTime=${time ?? "0"
          }&troubleshootDialog=false${autoPlay
            ? "&autoplay=true&muted=true&hideControlsOnStart=true&hideControls=true&restartAfterEnd=true&initDisabledControls=true"
            : ""
          }`}
        style={{
          border: "0",
          width: "100%",
          height: "100%",
        }}
      />
    </div>
  );
};

export default HomePandaVideoPlayer;
