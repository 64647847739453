import moment from "moment";
import "./CertificatePreview.css";

export default function CertCelsoLisboaBack({
  formData,
  selectedCourse,
  certificateContent
}) {
  const date = new Date();

  const actualNumericDate = String(
    date.toLocaleDateString("pt-BR", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      timeZone: "America/Sao_Paulo"
    })
  );

  const formatDate = (date) => {
    const dateArray = date.split("T")[0].split("-");
    return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
  };

  if (formData && certificateContent)
    return (
      <div className="relative flex flex-col overflow-hidden pb-8">
        <img
          src="/images/assets/backCertificado-CelsoLisboa.webp"
          alt="Verso do Certificado"
          className="w-full h-full select-none"
        />
        <div className="absolute top-0 left-0 w-full h-full select-none">
          {/* <div className="flex flex-col pl-[5.8rem] pr-[6rem] py-[7.7rem] gap-y-[1rem]"> */}
          <div className="pb-8">
            <div className="flex flex-col pt-[5%]">
              <div className="pl-[5%] pr-[17%] preview-grade-table-celso-lisboa">
                <table>
                  <thead>
                    <tr>
                      <th className="w-[40%] font-normal">
                        TITULAR: {String(formData.name)}
                      </th>
                      <th className="w-[60%] font-normal">
                        CURSO CONCLUÍDO: {String(selectedCourse.typeCourse)}{" "}
                        <i>Lato-sensu em</i>{" "}
                        <b>{String(selectedCourse.label)}</b>
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div className="bg-white pb-8 pl-[5%] pr-[17%] mt-1 preview-grade-table-celso-lisboa">
                <table>
                  <thead>
                    <tr>
                      <th className="table-text-center w-[36%]">DISCIPLINAS</th>
                      <th className="table-text-center w-[30px]">C.H.</th>
                      <th className="table-text-center w-[30px]">M.F.</th>
                      <th className="table-text-center">
                        NOME E TITULAÇÃO DOS PROFESSORES
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {certificateContent.table.disciplines.map(
                      (discipline, index) => (
                        <tr key={index}>
                          <td>{discipline.disciplineName}</td>
                          <td className="table-text-center">
                            {discipline.workload}
                          </td>
                          <td className="table-text-center">
                            {(Number(discipline.grade) || 0).toFixed(1)}
                          </td>
                          <td>
                            {discipline.teacherName} -{" "}
                            {discipline.teacherTitration}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
                <table className="mt-1">
                  <tbody>
                    <tr>
                      <td className="w-1/2 disable-right-border">
                        <div className="flex flex-col gap-y-1 font-bold text-center leading-relaxed">
                          <span>
                            Centro de Pós-Graduação e Extensão - C P G E <br />
                            Setor de Diplomas
                          </span>
                          <span>
                            Registro n°: **** Livro: ** Fls.: **** <br />
                            Rio de Janeiro, **/**/{moment().format("YYYY")}.
                          </span>
                        </div>
                      </td>
                      <td className="w-1/2 disable-left-border">
                        <div className="flex w-full justify-end pr-5">
                          <div className="flex flex-col gap-y-1 pl-[25%]">
                            <span>
                              CENTRO UNIVERSITÁRIO CELSO LISBOA
                              RECREDENCIAMENTO: PORTARIA N° 1.425 DE 02/08/2019
                              D. O. U. N° 150 DE 06/08/2019
                            </span>
                            <span>
                              CENTRO UNIVERSITÁRIO CELSO LISBOA
                              RECREDENCIAMENTO: PORTARIA N° 193 DE 25/01/2019 D.
                              O. U. N° 19 DE 28/01/2019
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="mt-1">
                  <tbody>
                    <tr>
                      <td className="w-1/6">
                        <span className="flex items-center justify-center text-center leading-relaxed">
                          OBSERVAÇÕES GERAIS
                        </span>
                      </td>
                      <td className="w-5/6">
                        <span className="leading-relaxed">
                          1. Período de realização do Curso:{" "}
                          {formatDate(certificateContent.table.courseDate)} à{" "}
                          {actualNumericDate}. <br />2 Total geral de horas do
                          Curso: {certificateContent.table.courseWorkload}{" "}
                          horas.
                          <br />3 Avaliação da aprendizagem: Através de
                          trabalhos e provas, em que são exigidos nota mínima
                          igual ou superior a 6.00 e 75% de frequência. <br />
                          4. Área do Conhecimento: {certificateContent.areaName}
                          .
                          {!certificateContent.table.tcc.dispense && (
                            <>
                              <br />
                              `5.Apresentação de Artigo com orientação da (o):
                              Profº $
                              {certificateContent.table.tcc.articleTeacher}
                              <br />
                              6. Apresentou Artigo sobre o título: “$
                              {certificateContent.table.tcc.title}”, obtendo
                              nota ${certificateContent.table.tcc.grade}`
                            </>
                          )}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
