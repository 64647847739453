import React, { useEffect, useState } from "react";
import "./recoveryPass.css";
import { Link } from "react-router-dom";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import Input from "../../components/Input/input";
import { HiMiniArrowLeft } from "react-icons/hi2";
import GuestFooter from "../../components/Footers/GuestFooter";
import { recoveryPassRequest } from "../../services/recoveryPass/recoveryPass.service";
import moment from "moment";
import "moment/locale/pt-br";
import { Toastify } from "../../components/Toastify/Toastify";
import NoAuthRequired from "../../services/noAuthRequired/noAuthRequired";
import getStudentInfos from "../../services/getStudentInfos/getStudentInfos";
import * as Sentry from "@sentry/react";
import { certifier } from "../../mocks/certifier";

const sign = require("jwt-encode");

function RecoveryPass() {
  const [submitState, setSubmitState] = useState(false);

  const [recoveryData, setRecoveryData] = useState({
    email: "",
  });

  const statusMessages = [
    {
      code: 404,
      message: "Usuário não encontrado.",
    },
    {
      code: 500,
      message: "Ocorreu um erro interno. Tente novamente em alguns minutos.",
    },
    {
      code: 5001,
      message: "E-mail vazio.",
    },
    {
      code: 5002,
      message: "E-mail ou senha inválida.",
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRecoveryData({ ...recoveryData, [name]: value });
  };

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitState(true);

    if (recoveryData.email === "") {
      Toastify("error", statusMessages[2].message);
      setSubmitState(false);
      return;
    }

    if (recoveryData.email.indexOf("@") === -1) {
      Toastify("error", statusMessages[3].message);
      setSubmitState(false);
      return;
    }

    const post_data = sign(
      {
        login: recoveryData.email,
        certifier: certifier,
        environment: process.env.REACT_APP_API_ENVIRONMENT,
        exp: moment().add(2, "hours").unix(),
        iat: moment().unix(),
      },
      process.env.REACT_APP_JWT_KEY
    );

    recoveryPassRequest(post_data)
      .then((res) => {
        if (res.status === 200) {
          Toastify("success", "E-mail enviado com sucesso!");
          setTimeout(() => {
            window.location.href = "/login";
          }, 2000);
          return;
        } else {
          setSubmitState(false);
          Toastify(
            "error",
            statusMessages.find((message) => message.code === res.status)
              ?.message ||
              "Ocorreu um erro interno. Tente novamente em alguns minutos."
          );
        }
      })
      .catch((err) => {
        Toastify(
          "error",
          statusMessages.find((message) => message.code === err.status)
            ?.message ||
            "Ocorreu um erro interno. Tente novamente em alguns minutos."
        );
      })
      .finally(() => {
        setSubmitState(false);
      });
  }

  useEffect(() => {
    const fetchStudentInfo = async () => {
      try {
        const student = await getStudentInfos();
        if (moment().unix() < student.exp) {
          window.location.href = "/dashboard";
        }
      } catch (error) {
        console.error("Erro ao buscar informações do estudante:", error);
        Sentry.setUser(null);

        localStorage.removeItem("user");
      }
    };
    fetchStudentInfo();
  }, []);

  return (
    <main className="flex w-screen h-screen max-lg:h-full overflow-hidden bg-backgroundOne text-textPrimary">
      <div className="w-fit min-w-[25vw] flex flex-col relative max-lg:hidden">
        <div className="flex w-full items-center justify-center">
          <img
            src={`/images/logos/logo-text-on-${certifier.colors.basics.primary_darkTheme ? "light" : "dark"}.webp`}
            alt="Logo"
            className="absolute w-1/3 top-5 z-[2]"
          />
        </div>
        <div className="w-auto h-full relative overflow-hidden">
          <img
            src={`/images/WebPageLogos/imageLogin.webp`}
            alt="Imagem da Logo"
            className="w-full h-full object-cover pointer-events-none select-none"
          />
        </div>
      </div>

      <section className="w-full h-[100vh] flex flex-col overflow-y-auto justify-between items-center">
        <div className="hidden max-lg:flex w-full h-full relative">
          <img
            src="/images/WebPageLogos/imageLogin-mobile.webp"
            alt="Logo"
            className="w-full object-cover"
          />
          <div className="absolute bottom-0 w-full bg-backgroundOne h-3 rounded-t-xl" />
        </div>
        <section className="max-lg:w-full max-lg:px-[5vw] w-[45%] mt-[10%] flex flex-col max-lg:pb-16 items-start gap-y-10">
          <div className="flex flex-col items-start gap-5">
            <Link
              to="/"
              className="text-themeColor font-normal flex items-center gap-x-1 text-sm link-type leading-none"
            >
              <HiMiniArrowLeft />
              <span>Voltar</span>
            </Link>
            <h1 className="font-semibold max-lg:text-[5vw] text-[2rem] text-left leading-none">
              Recuperar Acesso
            </h1>
            <span className="description-style leading-none">
              Digite seu endereço de e-mail e enviaremos um link para redefinir
              sua senha.
            </span>
          </div>
          <form
            onSubmit={handleSubmit}
            className="flex flex-col gap-y-7 w-full"
          >
            <div className="flex flex-col gap-y-3 w-full">
              <label className="span-small-white">E-mail</label>
              <Input
                type="email"
                name="email"
                value={recoveryData.email}
                onChange={handleChange}
              />
            </div>

            <div className="flex justify-center">
              <ButtonPrimary
                textButton="Enviar"
                desktopWidth="35%"
                mobileWidth="60%"
                disabled={submitState}
              />
            </div>
          </form>
        </section>

        <GuestFooter />
      </section>
    </main>
  );
}

export default NoAuthRequired(RecoveryPass);
