import React, { useEffect, useState } from "react";
import "./course.css";
import verifyAuth from "../../services/verifyAuth/verifyAuth";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import ModelClasses from "../../components/ModelClasses/ModelClasses";
import VideoClipSlide from "../../components/VideoSliderElements/VideoClipSlide";
import { getCourseByAlias } from "../../services/courseService/course.service";
import { useParams } from "react-router-dom";
import { Toastify } from "../../components/Toastify/Toastify";
import getStudentInfos from "../../services/getStudentInfos/getStudentInfos";
import LoadingSection from "../../components/LoadingElements/LoadingSection";
import MobileNavigationBar from "../../components/MobileNavigationBar/MobileNavigationBar";

function Course() {
  const [isLoading, setIsLoading] = useState(true);
  const [courseModels, setCourseModels] = useState(undefined);
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  const [navBar, setNavBar] = useState(0);
  const courseAlias = useParams().courseAlias;

  useEffect(() => {
    const fetchCourseInfo = async () => {
      try {
        const navBar = document.getElementById("mobile-navigation");
        if (navBar) setNavBar(navBar.offsetHeight);
        const student = await getStudentInfos();
        // getCourseByAlias --- START
        const arrayCourse = await getCourseByAlias({
          sessionId: student.sessionId,
          courseAlias: courseAlias,
        });
        if (arrayCourse.status !== 200) {
          Toastify("error", "Erro ao buscar informações de curso específico.");
          if (window) window.location.href = "/";
          return;
        }
        // getCourseByAlias --- END
        try {
          setCourseModels(arrayCourse.data);
        } catch {
          Toastify("error", "Erro ao processar itens do curso: #01");
        }
      } catch (error) {
        Toastify("error", "Erro generalizado ao buscar curso.");
        console.error("Erro ao buscar cursos: ", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCourseInfo();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <main className="flex flex-col justify-center w-full overflow-x-hidden bg-backgroundOne items-center text-textPrimary">
      <div
          style={{ height: windowWidth < 960 ? windowHeight - navBar : "auto"}} 
          className={`w-full justify-center items-center overflow-x-hidden ${
            windowWidth < 960
              ? "overflow-y-scroll custom-dark-scroll custom-transparent-scroll-background"
              : "flex flex-col"
          }`}
        >
          <HeaderBar
            actualPage="Aulas"
            courseNavigation={true}
            mobileMainText={courseModels ? courseModels.courseName : ""}
            mobileBackPage="/"
          />
          <MobileNavigationBar actualPage="Aulas" />
          <LoadingSection isLoading={isLoading} />
          {courseModels && courseModels.nextClass && (
            <>
              <VideoClipSlide
                limitedMode
                item={{
                  id: 0,
                  video: courseModels.nextClass.src,
                  imageThumb: courseModels.nextClass.thumb,
                  title: courseModels.nextClass.name,
                  description: courseModels.nextClass.description,
                }}
                continueWatching={
                  // courseModels.nextClass.progress > 2 ? true : false
                  courseModels.disciplines[0].classes[0] ?
                  courseModels.nextClass.classId ===
                  courseModels.disciplines[0].classes[0].classId
                    ? false
                    : true
                    : false
                }
                currentIndex={0}
                redirectTo={`/course/${courseAlias}/${courseModels.nextClass.disciplineId}/${courseModels.nextClass.classId}`}
              />
              <section className="flex relative w-full flex-col max-w-[1170px] mt-[5vh] justify-center items-center h-auto max-lg:mt-0">
                <section className="w-full flex flex-col gap-7 mb-7">
                  <section className="w-full flex justify-start max-lg:px-[5vw]">
                    <ModelClasses className="w-full" courseModels={courseModels} />
                  </section>
                </section>
              </section>
            </>
          )}
      </div>
    </main>
  );
}

export default verifyAuth(Course);
