import React, { forwardRef } from "react";
import { HiLockClosed } from "react-icons/hi2";
import "./DocumentViewer.css";
import { Helmet } from "react-helmet";

const DocumentViewer = forwardRef((props, ref) => {
  const { bottomButton, blockedStatus, src } = props;
  return (
    <div className="relative flex w-[100%] h-[100%] overflow-hidden rounded-t-xl">
      <Helmet>
        <link rel="stylesheet" href="/tailwind-full.css" />
      </Helmet>
      <div
        ref={ref}
        className="w-full h-[100%] overflow-y-scroll custom-dark-scroll custom-transparent-scroll-background rounded-t-xl border bg-white"
      >
        <div
          id="contract"
          className="text-black text-sm select-none pointer-events-none"
          style={{ lineHeight: "1.5rem" }}
          dangerouslySetInnerHTML={{ __html: src }}
        ></div>
      </div>
      {!blockedStatus && (
        <div
          className={`absolute ${
            window.innerWidth < 960 ? "hidden" : "flex"
          } bottom-0 w-full justify-center py-5`}
        >
          {bottomButton}
        </div>
      )}
      {blockedStatus && (
        <div className="absolute pointer-events-none w-full h-full flex justify-center items-center">
          <div className="bg-backgroundOne/70 rounded-full p-5">
            <HiLockClosed className="text-[2rem] text-textPrimary" />
          </div>
        </div>
      )}
    </div>
  );
});

export default DocumentViewer;
