import React, { useEffect, useState } from "react";
import verifyAuth from "../../services/verifyAuth/verifyAuth";
import { Toastify } from "../../components/Toastify/Toastify";
import LoadingSection from "../../components/LoadingElements/LoadingSection";
import { Link, useParams } from "react-router-dom";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import MobileNavigationBar from "../../components/MobileNavigationBar/MobileNavigationBar";
import { getPublicCourseInfo } from "../../services/courseService/course.service";
import VideoClipSlide from "../../components/VideoSliderElements/VideoClipSlide";
import "swiper/swiper-bundle.css";
import "./adquire.css";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import {
  HiBanknotes,
  HiCalendar,
  HiDevicePhoneMobile,
  HiLockClosed,
  HiLockOpen,
  HiMiniAcademicCap,
  HiMiniDocumentText,
  HiMiniGlobeAmericas,
  HiMiniStar,
  HiOutlineCurrencyDollar,
} from "react-icons/hi2";
import { BiSolidHourglass } from "react-icons/bi";
import { getCoursesRecommendedRequest } from "../../services/dashboardService/dashboard.service";
import getStudentInfos from "../../services/getStudentInfos/getStudentInfos";
import CourseList from "../../components/CourseList/CourseList";
import InternalFooter from "../../components/Footers/InternalFooter";
import { certifier } from "../../mocks/certifier";

function Adquire() {
  const [isLoading, setIsLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  const [navBar, setNavBar] = useState(0);
  const [course, setCourse] = useState(undefined);
  const [formattedCourses, setFormattedCourses] = useState([]);
  const [recommendedCourses, setRecommendedCourses] = useState([]);
  const courseAlias = useParams().courseAlias;
  const [expandedItems, setExpandedItems] = useState([]);
  const [collegeStar, setCollegeStar] = useState([
    { text: "5 estrelas no MEC", icon: <HiMiniStar size={30} /> },
    { text: "Início imediato", icon: <HiCalendar size={30} /> },
    {
      text: "Acesse pelo celular ou computador",
      icon: <HiDevicePhoneMobile size={30} />,
    },
    {
      text: "Disciplinas específicas do seu curso",
      icon: <HiMiniDocumentText size={30} />,
    },
    { text: "Certificado aprovado", icon: <HiMiniAcademicCap size={30} /> },
  ]);

  const solutionSpans = [
    {
      title: "Parcelas que cabem no seu bolso",
      description:
        "Invista menos que o valor de uma pizza e se torne um especialista.",
      icon: <HiBanknotes size={60} />,
    },
    {
      title: "Condições de pagamento",
      description: "Seja no Cartão, Boleto ou Pix, você terá a mesma condição.",
      icon: <HiOutlineCurrencyDollar size={60} />,
    },
    {
      title: "Zero burocracia",
      description:
        "Aplicação do cupom facilitada através de nossos consultores. Simples e cômodo.",
      icon: <HiMiniGlobeAmericas size={60} />,
    },
    {
      title: "Formação em 6 meses",
      description:
        "Lorem ipsum nananand ssanan nanananan na nanana dssdnan nananananan anananan",
      icon: <BiSolidHourglass size={60} />,
    },
  ];

  const toggleExpand = (index, state) => {
    setExpandedItems((prev) =>
      state ? [...prev, index] : prev.filter((i) => i !== index)
    );
  };

  useEffect(() => {
    const fetchCourseInfo = async () => {
      try {
        const student = await getStudentInfos();
        const arrayCourses = await getCoursesRecommendedRequest({
          sessionId: student.sessionId,
          viewAll: true,
        });

        const navBar = document.getElementById("mobile-navigation");
        if (navBar) setNavBar(navBar.offsetHeight);
        getPublicCourseInfo(courseAlias).then((response) => {
          if (response.status !== 200) {
            Toastify(
              "error",
              "Erro ao buscar informações de curso indisponível."
            );
            setTimeout(() => {
              window.location.href = "/";
            }, 1000);
            return;
          }
          setCourse(response.data);
          if (response.data.tccRequired) {
            setCollegeStar((prev) => [
              ...prev,
              { text: "TCC Obrigatório", icon: <HiLockClosed size={30} /> },
            ]);
          } else {
            setCollegeStar((prev) => [
              ...prev,
              { text: "TCC Opcional", icon: <HiLockOpen size={30} /> },
            ]);
          }
        });

        if (arrayCourses.status !== 200) {
          Toastify("error", "Erro ao buscar informações de cursos. - 3");
          console.error(
            "Erro ao buscar informações de cursos - 4:",
            arrayCourses.status
          );
        } else {
          setRecommendedCourses(arrayCourses.data.courses || []);
          setFormattedCourses(
            convertRecommendedCourses(arrayCourses.data.courses) || []
          );
        }
      } catch (error) {
        Toastify("error", "Erro generalizado ao buscar curso.");
        console.error("Erro ao buscar curso: ", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCourseInfo();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const convertRecommendedCourses = (recommendedCourses) => {
    return {
      areaName: "Cursos Recomendados",
      areaAlias: "recommendedCourses",
      specifiedRedirect: "/recommendedCourses",
      courses: recommendedCourses.map((course) => ({
        alias: course.alias,
        courseId: course.courseId,
        image: course.image,
        name: course.name,
        typeCourse: course.typeCourse,
        teacher: "",
        unlockCourse: false,
      })),
    };
  };

  return (
    <main className="flex flex-col justify-center w-full overflow-x-hidden bg-backgroundOne items-center text-textPrimary">
      <div
        style={{ height: windowWidth < 960 ? windowHeight - navBar : "auto" }}
        className={`w-full justify-center items-center overflow-x-hidden ${
          windowWidth < 960
            ? "overflow-y-scroll custom-dark-scroll custom-transparent-scroll-background"
            : "flex flex-col"
        }`}
      >
        <HeaderBar
          actualPage="Aulas"
          courseNavigation={true}
          mobileMainText={"nome do cursooo"}
          mobileBackPage="/"
        />
        <MobileNavigationBar actualPage="Aulas" />
        <LoadingSection isLoading={(isLoading || !course)} />
        {course && (
          <section className="flex flex-col gap-12 w-full items-center pb-12">
            <VideoClipSlide
              courses={recommendedCourses}
              adquireScreen={course}
              limitedMode
              item={{
                id: 0,
                video:
                  "https://player-vz-bfac27ef-501.tv.pandavideo.com.br/embed/?v=433f0717-8136-4086-8969-a04e22970970",
                imageThumb:
                  `/images/collegeContent/recommendation.webp`,
                title: course.name,
                // description: course.description ?? course.area[0].description,
                description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione iure consequuntur voluptate iusto veniam soluta consectetur, animi nesciunt officia, officiis eius voluptatem fugiat corporis atque aut reprehenderit ipsam similique cum.`,
                buttonText: "Adquira agora",
              }}
              // redirectTo={`${certifier.site.url.mainWebsite}/matricula/dados-iniciais`}
              redirectTo={`/matricula/dados-iniciais`}
            />
            <section className="flex w-full max-w-[1170px]">
              <section className="grid grid-cols-2 w-full gap-12">
                <section className="flex flex-col relative w-full gap-6">
                  <h2 className="font-bold text-[1.5rem] text-left leading-tight max-lg:text-[1.25rem]">
                    Sobre o curso
                  </h2>
                  <section className="grid grid-cols-3 w-fit rounded-xl pt-6 gap-3 shadow-lg">
                    {collegeStar.slice(0, 6).map((content, index) => (
                      <div
                        key={index}
                        className={`flex flex-col p-6 gap-6 w-[163px] h-[163px] bg-gray-950 rounded-xl transition-all duration-300 shadow-2xl justify-center items-center group hover:-translate-y-1`}
                      >
                        {content.icon}
                        <p className="w-full font-medium text-base leading-tight text-textPrimary text-center max-lg:text-sm">
                          {content.text}
                        </p>
                      </div>
                    ))}
                  </section>
                </section>
                <section className="flex flex-col relative w-full gap-y-6">
                  <h2 className="font-bold text-[1.5rem] text-left leading-tight max-lg:text-[1.25rem]">
                    Conteúdo programado
                  </h2>
                  <section className="flex flex-col w-[80%] rounded-xl pt-6 gap-6 shadow-lg h-full">
                    <div className="flex flex-col">
                      {course.curriculumns
                        .slice(0, 4)
                        .map((curriculum, index) => (
                          <div
                            key={index}
                            className="flex flex-col p-4 bg-gray-950 cursor-pointer overflow-hidden rounded-xl mb-2 transition-all duration-300 shadow-2xl"
                            style={{
                              marginTop: -(index * 10.8),
                              zIndex: 10 - index,
                              filter: `brightness(${1 - index * 0.2})`,
                              transform: `scaleX(${
                                (1 - index * 0.06) *
                                (expandedItems.includes(index) ? 1.03 : 1)
                              }) scaleY(${
                                (1 - index * 0.06) *
                                (expandedItems.includes(index) ? 1.03 : 1)
                              })`,
                            }}
                            onMouseEnter={() => toggleExpand(index, true)}
                            onMouseLeave={() => toggleExpand(index, false)}
                          >
                            <p className="w-full font-medium text-base leading-tight max-lg:text-sm text-textPrimary line-clamp-1 text-center">
                              {curriculum.name}
                            </p>
                          </div>
                        ))}
                    </div>
                    <Link
                      className="flex w-full justify-center"
                      // to={`${certifier.site.url.mainWebsite}/pos-graduacao/${course.area[0].alias}/curso/${course.alias}`}
                      to={`/pos-graduacao/${course.area[0].alias}/curso/${course.alias}`}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <ButtonPrimary
                        optionalBackground={certifier.colors.buttons.buttonTertiary}
                        optionalTextColor={certifier
                          .colors.buttons.buttonTertiary_darkTheme ? "#fff" : "#000"}
                        textButton={`Saiba mais`}
                        desktopWidth="245px"
                        mobileWidth="60%"
                      />
                    </Link>
                  </section>
                </section>
              </section>
            </section>
            <section className="flex swiper-cards-progress w-full flex-col gap-2 max-w-[1170px]">
              <CourseList area={formattedCourses} />
            </section>
            <section className="grid grid-cols-4 w-full flex-col gap-6 max-w-[1170px]">
              {solutionSpans.map((solution,index) => (
                <div index={index} className="flex flex-col w-full gap-6 p-6 bg-gray-950 rounded-lg">
                  {solution.icon}
                  <span className="w-full font-bold text-[1.5rem] text-left leading-tight max-lg:text-[1.25rem]">
                    {solution.title}
                  </span>
                  <p className="w-full font-medium text-base leading-tight max-lg:text-sm text-textPrimary">
                  {solution.description}
                  </p>
                </div>
              ))}
            </section>
          </section>
        )}
      </div>
      <InternalFooter />
    </main>
  );
}

export default verifyAuth(Adquire);
