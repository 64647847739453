import React, { useEffect, useState } from "react";
import { Toastify } from "../../components/Toastify/Toastify";
import verifyAuth from "../../services/verifyAuth/verifyAuth";
import SelectElement from "../../components/SelectElement/MainSelect";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import getStudentInfos from "../../services/getStudentInfos/getStudentInfos";
import LoadingSection from "../../components/LoadingElements/LoadingSection";
import { getListStudentCourses } from "../../services/courseService/course.service";
import {
  getAvaliationPage,
} from "../../services/avaliationServices/avaliation.service";
import "./avaliations.css";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import { CryptoService } from "../../services/cryptoService/crypto.service";
import BlockquoteModal from "../../components/Modals/BlockquoteModal";
import Cookies from "js-cookie";
import MobileNavigationBar from "../../components/MobileNavigationBar/MobileNavigationBar";
import { certifier } from "../../mocks/certifier";

function AvaliationsPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [coursesOptions, setCoursesOptions] = useState([]);
  const [selectedCourseOption, setSelectedCourseOption] = useState({});
  const [avaliationData, setAvaliationData] = useState([]);
  const [avaliationModal, setAvaliationModal] = useState(undefined);
  const [studentInfos, setStudentInfos] = useState(undefined);
  const [windowWidth, setWindowWidth] = useState(0);
  // const [windowHeight, setWindowHeight] = useState(0);
  const [navBar, setNavBar] = useState(0);
  const [navBarHeader, setNavBarHeader] = useState(0);

  useEffect(() => {
    async function fetchStudentCourses() {
      setIsLoading(true);
      try {
        const student = await getStudentInfos();
        setStudentInfos(student);
        const coursesResponse = await getListStudentCourses({
          sessionId: student.sessionId,
        });

        if (coursesResponse.status === 200) {
          const allCourses = coursesResponse.data
            .filter((course) => course.areaName !== "Softskills")
            .map((course) => ({
              label: course.courseName,
              value: course.courseAlias,
              courseId: course.courseId,
              matriculationId: course.matriculationId,
              typeCourse: course.typeCourse,
            }));

          setCoursesOptions(allCourses);
          if (allCourses.length > 0) {
            const lastCourseAccessed = Cookies.get(
              "lastCourseAccessed_avaliation"
            );
            if (lastCourseAccessed) {
              const lastCourse = allCourses.find(
                (course) => course.value === lastCourseAccessed
              );
              if (lastCourse) {
                setSelectedCourseOption(lastCourse);
                await fetchAvaliations(lastCourse.value);
              } else {
                setSelectedCourseOption(allCourses[0]);
                await fetchAvaliations(allCourses[0].value);
              }
            } else {
              setSelectedCourseOption(allCourses[0]);
              await fetchAvaliations(allCourses[0].value);
            }
          }
        } else {
          Toastify("error", "Erro ao buscar informações de todos os cursos.");
        }
        const navBar = document.getElementById("mobile-navigation");
        if (navBar) setNavBar(navBar.offsetHeight);
        const navBarHeader = document.getElementById("mainHeader");
        if (navBarHeader) setNavBarHeader(navBarHeader.offsetHeight);
      } catch (error) {
        Toastify("error", "Erro generalizado ao buscar os cursos.");
      } finally {
        setIsLoading(false);
      }
    }

    fetchStudentCourses();
  }, []);

  const handleSelectCourseChange = async (selectedOption) => {
    setSelectedCourseOption(selectedOption);
    await fetchAvaliations(selectedOption.value);
    Cookies.set("lastCourseAccessed_avaliation", selectedOption.value, {
      expires: 1 / 24,
    });
  };

  async function fetchAvaliations(courseAlias) {
    setIsLoading(true);
    try {
      const studentInfos = await getStudentInfos();
      const avaliationResponse = await getAvaliationPage({
        studentInfos,
        courseAlias,
      });
      if (avaliationResponse.status === 200) {
        setAvaliationData(
          avaliationResponse.data.map((item) => ({
            ...item,
            key: item.disciplineId,
          }))
        );
      } else {
        Toastify("error", "Erro ao buscar informações das avaliações.");
      }
    } catch (error) {
      Toastify("error", "Erro ao processar as avaliações.");
    } finally {
      setIsLoading(false);
    }
  }

  const handleDirectAvaliation = async (item) => {
    const avaliationData = {
      sessionId: studentInfos.sessionId,
      avaliationId: item.avaliationId,
      isViewAvaliation: true,
    };
    const cryptParam = await CryptoService(avaliationData, "encrypt-jwt");
    window.location.href = `/avaliation?key=${encodeURIComponent(
      String(cryptParam)
    )}`;
    setIsLoading(false);
  };

  const handleSelectAvaliation = (e) => {
    setAvaliationModal(e);
  };

  const handleStartAvaliation = async () => {
    try {
      setIsLoading(true);
      Cookies.set("lastCourseAccessed_avaliation", selectedCourseOption.value, {
        expires: 1 / 24,
      });
      const avaliationData = {
        sessionId: studentInfos.sessionId,
        matriculationId: selectedCourseOption.matriculationId,
        courseId: selectedCourseOption.courseId,
        courseAlias: selectedCourseOption.value,
        disciplineId:
          avaliationModal.type === "avf" ? null : avaliationModal.disciplineId,
        avaliationType: avaliationModal.type,
      };
      const cryptParam = await CryptoService(avaliationData, "encrypt-jwt");
      window.location.href = `/avaliation?key=${encodeURIComponent(
        String(cryptParam)
      )}`;
    } catch (error) {
      Toastify("error", "Erro ao iniciar avaliação.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      // setWindowHeight(window.innerHeight);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <main className="flex flex-col w-full overflow-x-hidden bg-backgroundOne items-center text-textPrimary min-h-screen">
      <HeaderBar
        courseNavigation={false}
        actualPage="Provas"
        mobileMainText="Provas"
        mobileBackPage="/"
        mobileMinHeaderHeight={0}
      />
      <MobileNavigationBar actualPage="Provas" />
      <LoadingSection isLoading={isLoading} />
      <section
        style={{
          paddingBottom: windowWidth < 960 ? navBar : 0,
          paddingTop: windowWidth < 960 ? navBarHeader : 0,
        }}
        className={`overflow-hidden mt-9 mb-9 flex ${
          windowWidth < 960 ? "h-[auto]" : ""
        } flex-col gap-5 w-full max-w-[1170px] bg-backgroundThree rounded-xl max-mb:mt-0 max-mb:mb-0 max-mc:rounded-none`}
      >
        {/* Cabeçalho */}
        <div className="p-10 flex justify-between min-[860px]:items-center max-[860px]:flex-col max-[860px]:gap-8 max-[860px]:p-6">
          <div className="flex flex-col w-full h-full">
            <div>
              <h1 className="my-2 text-3xl text-textPrimary font-bold leading-[1.35]">
                Avaliações Online
              </h1>
            </div>
            <span className="text-textSecondary text-sm">
              Realize as avaliações disponíveis e confira suas notas.
            </span>
          </div>
          {coursesOptions.length > 0 && (
            <div className="w-[60%] max-[860px]:w-full">
              <SelectElement
                options={coursesOptions}
                value={selectedCourseOption.value}
                onChange={handleSelectCourseChange}
              />
            </div>
          )}
        </div>
        {/* Avaliações */}
        <div className="w-full">
          {/* Header da tabela, substituindo gridTitles */}
          <div className="discipline-grid-avaliations px-10 max-mc:px-7 text-sm bg-backgroundTwo sticky shadow-md">
            <div className="py-4 font-semibold">Avaliações</div>
            <div className="text-center py-4 font-semibold max-md:hidden">
              Realização
            </div>
            <div className="text-center py-4 font-semibold">Nota</div>
            <div className="text-center py-4 font-semibold">Situação</div>
          </div>
          {/* Itens da tabela, agora utilizando avaliationData */}
          <div className="flex flex-col w-full">
            {avaliationData.map((item, index) => (
              <button
                onClick={() => {
                  if (item.blockAvaliation || item.dispense) {
                    return;
                  } else if (
                    (item.situation === "Reprovado" ||
                      item.situation === "Aprovado") &&
                    item.tries >= item.retries &&
                    !item.fake
                  ) {
                    handleDirectAvaliation(item);
                  } else if (
                    !item.fake ||
                    (item.fake && item.tries < item.retries)
                  ) {
                    handleSelectAvaliation(item);
                  }
                }}
                key={index}
                className={`text-center px-10 max-mc:px-7 discipline-grid-avaliations items-center bg-backgroundTwo ${
                  (item.blockAvaliation ||
                    item.dispense ||
                    (item.fake && item.tries >= item.retries)) &&
                  "cursor-default"
                } ${
                  item.blockAvaliation && !item.fake
                    ? "text-textSecondary"
                    : "hover:bg-backgroundThree"
                }`}
              >
                <div className="text-left py-5 truncate">
                  <p className="text-sm truncate">{item.disciplineName}</p>
                  {item.tries < item.retries &&
                    !item.dispense &&
                    item.situation !== "Disponível" &&
                    item.situation !== "Indisponível" && (
                      <p className="text-xs text-textSecondary truncate">
                        Você ainda tem {item.retries - item.tries} tentativa(s)
                      </p>
                    )}
                  {item.situation === "Indisponível" && item.dateUnlock && (
                    <p className="text-xs text-textSecondary truncate">
                      {item.dateUnlock}
                    </p>
                  )}
                </div>
                <div className="py-5 text-sm font-normal max-md:hidden">
                  {item.dateFinish}
                </div>
                <div className="py-5 text-sm font-normal">{item.grade}</div>
                <div className="py-5 text-sm font-normal">
                  <div className="flex w-full justify-center">
                    <span
                      className={` ${
                        item.situation === "Aprovado"
                          ? "uppercase select-none font-bold px-2 w-fit py-0.5 rounded-full text-xs tracking-wide text-[#3730a3] bg-[#e0e7ff]"
                          : ""
                      } ${
                        item.situation === "Reprovado"
                          ? "uppercase select-none font-bold px-2 w-fit py-0.5 rounded-full text-xs tracking-wide text-[#991b1b] bg-[#fee2e2]"
                          : ""
                      } ${
                        item.situation === "Em andamento" ||
                        item.situation === "Disponível"
                          ? "uppercase select-none font-bold px-2 w-fit py-0.5 rounded-full text-xs tracking-wide text-[#924049] bg-[#fef3c7]"
                          : ""
                      } ${
                        item.situation === "Indisponível"
                          ? "uppercase select-none font-bold px-2 w-fit py-0.5 rounded-full text-xs tracking-wide text-gray-500 bg-gray-100"
                          : ""
                      } `}
                    >
                      {item.situation}
                    </span>
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>
      </section>
      {/* Modal */}
      {avaliationModal && (
        <BlockquoteModal
          text_top="Realizar avaliação"
          text_blockquote={
            avaliationModal.situation !== "Em andamento"
              ? `A prova terá uma duração de ${String(
                  avaliationModal.duration / 60
                ).replace(".", ",")} hora${
                  avaliationModal.duration / 60 >= 2 ? "s" : ""
                }. Além disso, você terá ${String(
                  avaliationModal.retries - avaliationModal.tries
                )} tentativa${
                  avaliationModal.retries >= 2 ? "(s)" : ""
                } para realizar a avaliação. Está pronto para iniciar?`
              : "Você tem certeza de que deseja dar continuidade a esta avaliação?"
          }
          outsideClick={() => setAvaliationModal(undefined)}
          buttonsLeft={
            (avaliationModal.situation === "Reprovado" ||
              avaliationModal.situation === "Aprovado") &&
            !avaliationModal.fake
              ? [
                  <ButtonPrimary
                    disableHorizontalMovement
                    optionalHoverBackground="#00000000"
                    optionalBackground="#00000000"
                    optionalTextColor={certifier.colors.text.textPrimary}
                    textButton="Ver avaliação"
                    desktopWidth="160px"
                    mobileWidth="160px"
                    verifyOnClick={() =>
                      handleDirectAvaliation(avaliationModal)
                    }
                    disabled={studentInfos.userTeste}
                  />,
                ]
              : []
          }
          buttonsRight={
            ((avaliationModal.situation === "Reprovado" ||
              avaliationModal.situation === "Aprovado") &&
              avaliationModal.tries < avaliationModal.retries) ||
            avaliationModal.situation === "Disponível" ||
            avaliationModal.situation === "Em andamento"
              ? [
                  <ButtonPrimary
                    verifyOnClick={() => setAvaliationModal(undefined)}
                    disableHorizontalMovement
                    optionalHoverBackground="#00000000"
                    optionalBackground="#00000000"
                    textButton="Cancelar"
                    optionalTextColor={certifier.colors.text.textPrimary}
                    desktopWidth="160px"
                    mobileWidth="160px"
                  />,
                  <ButtonPrimary
                    verifyOnClick={handleStartAvaliation}
                    textButton={`${
                      avaliationModal.situation === "Em andamento"
                        ? "Continuar"
                        : "Começar"
                    }`}
                    disabled={studentInfos.userTeste}
                  />,
                ]
              : [
                  <ButtonPrimary
                    verifyOnClick={() => setAvaliationModal(undefined)}
                    optionalTextColor={certifier.colors.text.textPrimary}
                    disableHorizontalMovement
                    textButton="Voltar"
                    desktopWidth="160px"
                    mobileWidth="160px"
                  />,
                ]
          }
        />
      )}
    </main>
  );
}

export default verifyAuth(AvaliationsPage);
