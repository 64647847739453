import React, { useEffect, useState } from "react";
import "./HeaderBar.css";
import { Link } from "react-router-dom";
import {
  HiMiniNewspaper,
  HiMiniBriefcase,
  HiMiniCurrencyDollar,
  HiClipboardDocument,
  HiClipboardDocumentList,
  HiIdentification,
  HiDocumentCheck,
  HiDocumentText,
  HiMiniFolder,
  HiMiniArrowRightOnRectangle,
  HiMiniArrowLeft,
  HiMiniArrowLeftCircle,
  HiOutlineUserCircle,
  HiHome,
} from "react-icons/hi2";
import { HiMiniChevronDown } from "react-icons/hi2";
import { logoutRequest } from "../../services/logout/logout.service";
import moment from "moment";
import "moment/locale/pt-br";
import HeaderSpan from "./HeaderSpan/HeaderSpan";
import getStudentInfos from "../../services/getStudentInfos/getStudentInfos";
import * as Sentry from "@sentry/react";
import { certifier } from "../../mocks/certifier";
const sign = require("jwt-encode");

function HeaderBar({
  courseNavigation,
  actualPage,
  mobileMainText,
  mobileBackPage,
  mobileMinHeaderHeight,
  disableBlurBackground,
  disableBackButton,
  hiddenHeaderOnLG,
}) {
  const [showHeader, setShowHeader] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(50);
  const [profileOpen, setProfileOpen] = useState(false);
  const [isSpanHovered, setIsSpanHovered] = useState(false);
  const [sessionName, setSessionName] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  // const [windowWidth, setWindowWidth] = useState(0);
  // const [windowHeight, setWindowHeight] = useState(0);
  const [isPageTop, setIsPageTop] = useState(true);
  const [isFirstAccess, setIsFirstAccess] = useState(false);
  // const [hiddenHeaderWidth, setHiddenHeaderWidth] = useState(50);

  const avaliationsSpan = [
    {
      icon: <HiClipboardDocumentList className="w-6 h-6" />,
      title: "Avaliações",
      description:
        "Realize as avaliações das disciplinas do seu curso e confira suas notas",
      url: "/avaliations",
    },
    {
      icon: <HiClipboardDocument className="w-6 h-6" />,
      title: "Trabalho de Conclusão de Curso",
      description:
        "Construa sua monografia ou artigo, caso seja possível, dispense o TCC.",
      url: "/tcc",
    },
  ];

  const docsSpans = [
    {
      icon: <HiDocumentCheck className="w-6 h-6" />,
      title: "Declarações",
      description: "Precisa de alguma declaração? Veja os modelos disponíveis.",
      url: "/user/declarations",
    },
    {
      icon: <HiIdentification className="w-6 h-6" />,
      title: "Documentos",
      description:
        "Envie e acompanhe a aprovação dos seus documentos pessoais.",
      url: "/user/documents",
    },
    {
      icon: <HiDocumentText className="w-6 h-6" />,
      title: "Contratos",
      description: "Acompanhe a situação de seus contratos de cursos.",
      url: "/user/contracts",
    },
    {
      icon: <HiMiniFolder className="w-6 h-6" />,
      title: "Certificados",
      description: "Chegou a hora tão esperada? Solicite o seu certificado.",
      url: "/user/certificates",
    },
  ];

  const menuItems = [
    {
      name: "Início",
      type: "url",
      link: "/dashboard",
      icon: <HiHome className="w-6 h-6 " />,
    },
    {
      name: "Provas",
      type: "onScreen",
      element: (
        <HeaderSpan
          headerHeight={headerHeight}
          objectsSpans={avaliationsSpan}
        />
      ),
      icon: <HiMiniNewspaper className="w-6 h-6" />,
    },
    {
      name: "Documentos",
      type: "onScreen",
      element: (
        <HeaderSpan headerHeight={headerHeight} objectsSpans={docsSpans} />
      ),
      icon: <HiMiniBriefcase className="w-6 h-6" />,
    },
    {
      name: "Financeiro",
      type: "url",
      link: "/finance",
      icon: <HiMiniCurrencyDollar className="w-6 h-6" />,
    },
  ];

  const profileItems = [
    {
      name: "Perfil",
      type: "link",
      link: "/user/basicInfos",
      icon: <HiOutlineUserCircle className="w-6 h-6" />,
    },
    {
      name: "Sair",
      type: "button",
      parameter: "logout",
      icon: <HiMiniArrowRightOnRectangle className="w-6 h-6 " />,
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setProfileOpen(false);
      setShowHeader(window.scrollY > headerHeight * 1.5);
      if (typeof mobileMinHeaderHeight === "undefined") {
        setIsPageTop(window.scrollY <= window.innerHeight * 0.85);
      } else if (
        typeof mobileMinHeaderHeight !== "undefined" &&
        mobileMinHeaderHeight === 0
      ) {
        setIsPageTop(false);
      } else {
        setIsPageTop(
          window.scrollY <= window.innerHeight * (mobileMinHeaderHeight / 100)
        );
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const headerComponent = document.getElementById("mainHeader");
    if (headerComponent) {
      setHeaderHeight(headerComponent.offsetHeight);
    }
  }, [showHeader]);

  const handleProfileClick = () => {
    setProfileOpen(!profileOpen);
  };

  const handleProfileAction = async (action) => {
    if (action === "logout") {
      try {
        const studentInfo = await getStudentInfos();
        if (studentInfo) {
          const sessionId = studentInfo.sessionId;
          const post_data = sign(
            {
              sessionId,
              environment: process.env.REACT_APP_API_ENVIRONMENT,
              exp: moment().add(2, "hours").unix(),
              iat: moment().unix(),
            },
            process.env.REACT_APP_JWT_KEY
          );
          logoutRequest(post_data)
            .then((res) => {
              if (res.status === 200) {
                Sentry.setUser(null);

                localStorage.removeItem("user");
                window.location.href = "/login";
                return;
              }
            })
            .catch((err) => {
              console.error("Erro ao processar a solicitação.");
            });
        } else {
          throw new Error("No user session found");
        }
      } catch (error) {
        console.error(
          "Erro sessão inválida ou ao buscar informações do estudante:",
          error
        );
        window.location.href = "/login";
      }
    }
  };

  useEffect(() => {
    const fetchStudentInfo = async () => {
      try {
        const student = await getStudentInfos();
        setSessionName(student.name);
        setIsFirstAccess(student.firstAccess);
      } catch (error) {
        console.error("Erro ao buscar informações do estudante:", error);
      }
    };
    fetchStudentInfo();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      // setWindowWidth(window.innerWidth);
      // setWindowHeight(window.innerHeight);
      setIsMobile(window.innerWidth <= 1024);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {!courseNavigation && (
        <div
          id="hiddenHeader"
          className={hiddenHeaderOnLG ? "max-lg:hidden" : "max-mb:hidden"}
          style={{ height: headerHeight }}
        />
      )}
      {isMobile ? (
        <>
          <header
            id="mainHeader"
            className={`z-[103] flex w-full justify-center items-center top-0 fixed min-h-[40px]`}
          >
            {!disableBlurBackground && (
              <div
                className={`absolute w-full h-[60px] backdrop-blur-[1rem] bg-backgroundOne/80 ${
                  isPageTop && "hidden"
                }`}
              />
            )}
            {!disableBlurBackground &&
              (mobileMainText ? (
                <h1
                  className={`mt-3 z-[101] text-textPrimary text-[1rem] text-center font-bold w-[70vw] text-ellipsis overflow-hidden line-clamp-1 ${
                    isPageTop && "hidden"
                  }`}
                >
                  {mobileMainText}
                </h1>
              ) : (
                <img
                  src={`/images/logos/logo-text-on-${certifier.colors.basics.primary_darkTheme ? "light" : "dark"}.webp`}
                  alt="Logo"
                  className={`mt-3 z-[101] h-[1.25rem] ${
                    isPageTop && "hidden"
                  }`}
                />
              ))}
            {!disableBackButton && (
              <Link
                to={mobileBackPage ? mobileBackPage : "/"}
                className={`absolute top-[16px] left-0 pl-[5vw] text-textPrimary z-[102] ${
                  isFirstAccess && "hidden select-none pointer-events-none"
                }`}
              >
                <HiMiniArrowLeft className="text-xl" />
              </Link>
            )}
          </header>
        </>
      ) : (
        !isMobile && (
          <header
            id="mainHeader"
            className={`z-[100] flex w-full justify-center top-0 fixed 
        ${
          showHeader
            ? "slide-from-top bg-barColor85"
            : "slide-from-top backdrop-blur-[1rem]"
        }`}
            style={{
              width: showHeader && "100%",
            }}
          >
            <section
              className={`w-full flex items-center gap-12 max-w-[1170px] min-h-[5rem] ${
                isFirstAccess ? "justify-center relative" : "justify-between"
              }`}
            >
              <div className="w-[17%]">
                <Link
                  className={
                    isFirstAccess
                      ? "pointer-events-none select-none"
                      : "cursor-pointer"
                  }
                  to={isFirstAccess ? "#" : "/"}
                >
                  <img
                    src={`/images/logos/logo-text-on-${certifier.colors.basics.primary_darkTheme ? "light" : "dark"}.webp`}
                    alt="Logo"
                    className="w-[70%]"
                  />
                </Link>
              </div>
              {!isFirstAccess && (
                <div className="w-[60%] flex justify-center">
                  {menuItems.map((item, index) =>
                    item.type === "url" ? (
                      <Link
                        to={`${item.link}`}
                        key={index}
                        className={`font-normal text-sm cursor-pointer flex items-center gap-3 h-full px-6 py-7 transition-all duration-300 ${
                          actualPage === item.name
                            ? "text-hoverIconAndText"
                            : "text-iconAndText hover-text-hoverIconAndText"
                        }`}
                      >
                        {item.icon}
                        <span>{item.name}</span>
                      </Link>
                    ) : (
                      <button
                        key={index}
                        className={`font-normal text-sm cursor-pointer flex items-center gap-3 h-full px-6 py-7 transition-all duration-300 group ${
                          actualPage === item.name
                            ? "text-hoverIconAndText"
                            : "text-iconAndText hover-text-hoverIconAndText"
                        }`}
                        onMouseEnter={() => {
                          setIsSpanHovered(true);
                          setProfileOpen(false);
                        }}
                        onMouseLeave={() => setIsSpanHovered(false)}
                      >
                        {item.icon}
                        <span>{item.name}</span>
                        {item.element}
                      </button>
                    )
                  )}
                </div>
              )}
              <div
                className={`w-[20%] flex justify-end ${
                  isFirstAccess ? "hidden" : ""
                }`}
              >
                <div className="relative">
                  <button
                    onClick={handleProfileClick}
                    className="z-[12] flex w-full gap-2 items-center justify-end"
                  >
                    <div className="rounded-full bg-white w-[1.75rem] h-[1.75rem] text-base text-black flex items-center justify-center text-center">
                      {sessionName.charAt(0)}
                    </div>
                    <div className="font-normal text-sm text-iconAndText">
                      {sessionName.split(" ")[0]}
                    </div>
                    <HiMiniChevronDown
                      className={`w-5 h-5 cursor-pointer transition-all duration-300 ease-in-out ${
                        profileOpen
                          ? "-rotate-180 text-iconAndText"
                          : "text-hoverIconAndText"
                      }`}
                    />
                  </button>
                  <div
                    onBlur={() => setProfileOpen(false)}
                    className={`absolute left-0 top-[120%] rounded-md transition-all duration-300 ease-in-out ${
                      profileOpen
                        ? "translate-x-0"
                        : "-translate-y-12 opacity-0 pointer-events-none cursor-none"
                    }`}
                  >
                    {profileItems.map((item, index) =>
                      item.type === "link" ? (
                        <Link
                          to={item.link}
                          key={index}
                          className="flex items-center gap-3 pr-5 py-3 cursor-pointer transition-all duration-300 ease-in-out font-normal text-sm text-iconAndText hover-text-hoverIconAndText"
                        >
                          {item.icon}
                          <span>{item.name}</span>
                        </Link>
                      ) : (
                        <button
                          key={index}
                          onClick={() => {
                            handleProfileAction(item.parameter);
                          }}
                          className="flex items-center gap-3 pr-5 py-3 cursor-pointer transition-all duration-300 ease-in-out font-normal text-sm text-iconAndText hover-text-hoverIconAndText"
                        >
                          {item.icon}
                          <span>{item.name}</span>
                        </button>
                      )
                    )}
                  </div>
                </div>
              </div>
            </section>
            <div
              className={`z-[-1] pointer-events-none w-full top-0 h-full absolute opacity-100 ${
                isSpanHovered
                  ? "bg-barColor"
                  : "navigation-transparence"
              }`}
              style={{
                height: headerHeight + "px",
              }}
            />
          </header>
        )
      )}
    </>
  );
}

export default HeaderBar;
