import "./DiscoveryCourseWithAI.css";

import { useEffect, useState } from "react";
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";
import { HiBeaker } from "react-icons/hi2";
import ReactDOM from "react-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import getStudentInfos from "../../../services/getStudentInfos/getStudentInfos";
import { Toastify } from "../../Toastify/Toastify";
import { Link } from "react-router-dom";
import { getAICourses } from "../../../services/courseService/course.service";
import { Player } from "@lottiefiles/react-lottie-player";
import { certifier } from "../../../mocks/certifier";

export default function DiscoveryCourseWithAI({ courses }) {
  const [openPopUp, setOpenPopUp] = useState(false);
  const [popupContainer, setPopupContainer] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [isAIContent, setIsAIContent] = useState(0);
  const [courseLists, setCourseLists] = useState(undefined);
  const [activeSwiperIndex, setActiveSwiperIndex] = useState(0);
  const [lastActiveSwiperIndex, setLastActiveSwiperIndex] = useState(0);
  const [textIndex, setTextIndex] = useState(0);
  const indexLimit = 1;
  
  useEffect(() => {
    const timer = setTimeout(() => {
      if (textIndex >= indexLimit) {
        setTextIndex(0);
      } else {
        setTextIndex(textIndex + 1);
      }
    }, 5000);
    return () => clearTimeout(timer);
  }, [textIndex]);

  useEffect(() => {
    const container = document.createElement("div");
    document.body.prepend(container);
    setPopupContainer(container);

    return () => {
      document.body.removeChild(container);
    };
  }, []);

  const handlePopUp = () => {
    setOpenPopUp(!openPopUp);
  };

  const handleIconClick = async () => {
    let oldCourseLists = courseLists;

    if (inputValue === "" || inputValue.length < 5) {
      Toastify("error", "Preencha o campo de busca.");
      return;
    }

    try {
      const student = await getStudentInfos();
      setCourseLists([]);
      const response = await getAICourses({
        sessionId: student.sessionId,
        studentPrompt: inputValue,
      });

      if (response.status !== 200) {
        setCourseLists(oldCourseLists);
        console.error(`Wrong I.A. status`, response.response.message)
        Toastify("error", response.response.message);
        setIsAIContent(2);
        setTimeout(()=>{setIsAIContent(0)},5000)
        return;
      }
      setInputValue("");
      setCourseLists(response.data);
      setIsAIContent(1);
    } catch (error) {
      Toastify("error", "Erro ao buscar cursos com I.A.");
      console.error(`Wrong I.A. log`, error.message);
      setCourseLists(oldCourseLists);
      setIsAIContent(2);
      setTimeout(()=>{setIsAIContent(0)},8000)
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleIconClick();
    }
  };

  useEffect(() => {
    setCourseLists(courses || []);
  }, []);

  return (
    <>
      <ButtonPrimary
        optionalIcon={<HiBeaker size={20} />}
        absoluteIcon
        optionalBackground="#111827"
        optionalTextColor="#d1d5db"
        textButton={`Descubra seu próximo curso!`}
        desktopWidth="100%"
        mobileWidth="100%"
        verifyOnClick={handlePopUp}
      />
      {openPopUp &&
        popupContainer &&
        ReactDOM.createPortal(
          <div className="fixed top-0 left-0 w-full h-full z-[199] flex items-center justify-center bg-g">
            <div
              className="absolute w-full h-full bg-backgroundOne/40 backdrop-blur-[1px]"
              onClick={handlePopUp}
            />
            <section
              className={`flex flex-col z-[201] text-textPrimary rounded-3xl p-10 bg-gray-950 w-full max-w-[700px] max-h-[95vh] overflow-x-hidden overflow-scroll custom-dark-scroll custom-transparent-scroll-background relative transition-all duration-300 ${
                courseLists.length ? "min-h-[85vh]" : "min-h-[40vh]"
              }`}
            >
              <div className="relative mb-6">
                <input
                  className="w-full rounded-full cursor-text px-10 py-3 font-semibold text-sm group transition-all duration-300 shadow-md disabled:opacity-50"
                  style={{
                    backgroundColor: `rgb(17, 24, 39)`,
                    color: `white`,
                  }}
                  placeholder="Qual é a área de estudo ou especialização que mais te interessa?"
                  value={inputValue}
                  disabled={typeof courseLists !== "undefined" && courseLists.length === 0}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
                <HiBeaker
                  className="absolute right-5 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  size={24}
                  color="#6b7280"
                  onClick={handleIconClick}
                />
                <style jsx>{`
                  input::placeholder {
                    color: rgb(107, 114, 128);
                  }
                `}</style>
              </div>
              <h2 className="w-full text-3xl text-textPrimary font-bold max-lg:leading-tight max-lg:text-2xl max-lg:text-center line-clamp-2">
                {courseLists.length
                  ? isAIContent
                    ? isAIContent === 1
                      ? "Escolhidos para você"
                      : "Tente novamente mais tarde"
                    : "Com base nos seus interesses"
                  : "Preparando resultados..."}
              </h2>
              <div className="w-full h-full">
                {!courseLists.length ? (
                  <div className="flex w-full h-full items-center justify-center z-[1111] pt-[48px]">
                    <Player
                      autoplay
                      loop
                      src={`/images/logos/ai-loading.json`}
                      className="w-full h-[60px]"
                    />
                  </div>
                ) : (
                  <Swiper
                    spaceBetween={2}
                    slidesPerView={3}
                    centeredSlides={true}
                    loop={true}
                    className="mySwiper"
                    onSwiper={(swiper) => {
                      swiper.slides.forEach((slide) => {
                        slide.style.cursor = "grab";
                      });
                    }}
                    onSlideChangeTransitionStart={(swiper) => {
                      setActiveSwiperIndex(null);
                      swiper.slides.forEach((slide) => {
                        slide.style.cursor = "grab";
                      });
                    }}
                    onSlideChangeTransitionEnd={(swiper) => {
                      setTimeout(() => {
                        setActiveSwiperIndex(swiper.realIndex || 0);
                        setLastActiveSwiperIndex(swiper.realIndex || 0);
                      }, 100);
                    }}
                  >
                    {courseLists.map((course, index) => (
                      <SwiperSlide key={index}>
                        {({ isActive }) => (
                          <div
                            className={`relative p-2 transition-all duration-300 my-10 ${
                              isActive
                                ? "opacity-100 scale-110 z-10"
                                : "opacity-80 scale-90"
                            }`}
                          >
                            <div
                              className={`transition-all duration-300 rounded-xl shadow-lg overflow-hidden h-full active:cursor-grabbing`}
                              style={{
                                filter: isActive ? "none" : "brightness(0.2)",
                              }}
                            >
                              <img
                                src={course.image}
                                alt={course.title}
                                className="w-full h-full object-cover select-none"
                              />
                            </div>
                          </div>
                        )}
                      </SwiperSlide>
                    ))}
                  </Swiper>
                )}
                {courseLists.length ? (
                  <div
                    className={`transition-all duration-300 ${
                      activeSwiperIndex === null && "opacity-70"
                    } absolute left-0 bottom-0 p-10 flex flex-col justify-center w-full z-[202] min-h-[150px]`}
                    style={{
                      background: `linear-gradient(to top, rgba(3, 7, 18,100), rgba(3, 7, 18,0.75) 75%, transparent)`,
                    }}
                  >
                    <h2
                      className={`transition-all duration-300 ${
                        activeSwiperIndex === null && "opacity-0"
                      } w-full text-3xl text-textPrimary font-bold leading-none max-lg:leading-tight max-lg:text-2xl max-lg:text-center line-clamp-2 select-none pointer-events-none pb-5 pt-1`}
                    >
                      {courseLists[lastActiveSwiperIndex].name}
                    </h2>
                    <p
                      className={`transition-all duration-300 ${
                        activeSwiperIndex === null && "opacity-0"
                      } text-textPrimary text-sm max-lg:text-center line-clamp-3 select-none pointer-events-none mb-5`}
                    >
                      {/* {courseLists[lastActiveSwiperIndex].description} */}
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt, porro vitae placeat odio minus, dolore dicta nesciunt aperiam nulla illum unde aspernatur! Placeat eos explicabo quaerat laboriosam quidem tempore delectus!
                    </p>
                    <div
                      className={`transition-all duration-300 ${
                        activeSwiperIndex === null && "opacity-0"
                      } flex justify-between items-center w-full`}
                    >
                      <div className="flex items-center gap-3 w-fit h-fit group">
                        <div className="flex relative w-8 h-8">
                          <div className="shine-effect absolute bg-backgroundTwo rounded-md flex w-8 h-8 items-center justify-center transition-all duration-300">
                            <img
                              alt="Faculdade Libano"
                              src={`/images/logos/logo-${certifier.colors.basics.primary_darkTheme ? "light" : "dark"}.webp`}
                              className="w-5 h-5 transition-all duration-300"
                            />
                          </div>
                        </div>

                        <div className="flex items-center min-w-[220px] h-5 relative overflow-hidden">
                          <div
                            className={`absolute flex items-center gap-2 w-fit transition-all duration-300 group-hover:ml-0.5 ${
                              textIndex === 0
                                ? "translate-y-0 opacity-100"
                                : "translate-y-full opacity-0"
                            }`}
                          >
                            <p
                              className={`text-textPrimary text-sm max-lg:text-center`}
                            >
                              {courseLists[lastActiveSwiperIndex].typeCourse}
                            </p>
                            <p
                              className={`text-textPrimary text-sm max-lg:text-center`}
                            >
                              •
                            </p>
                            <p
                              className={`text-textPrimary text-sm max-lg:text-center`}
                            >
                              <b>{courseLists[lastActiveSwiperIndex].minCourseTime} meses</b>
                            </p>
                          </div>
                          <div
                            className={`absolute flex items-center gap-2 w-fit transition-all duration-300 group-hover:ml-0.5 ${
                              textIndex === 1
                                ? "translate-y-0 opacity-100"
                                : "translate-y-full opacity-0"
                            }`}
                          >
                            <p
                              className={`text-textPrimary text-sm max-lg:text-center`}
                            >
                              <b>Nota 5 no MEC</b>
                            </p>
                            <p
                              className={`text-textPrimary text-sm max-lg:text-center`}
                            >
                              •
                            </p>
                            <p
                              className={`text-textPrimary text-sm max-lg:text-center`}
                            >
                              {courseLists[lastActiveSwiperIndex].workload} horas
                            </p>
                          </div>
                        </div>
                      </div>
                      <Link
                        to={`https://faculdadelibano.edu.br/pos-graduacao/${courseLists[lastActiveSwiperIndex].areaAlias}/curso/${courseLists[lastActiveSwiperIndex].alias}`}
                        target="_blank"
                      >
                        <ButtonPrimary
                          optionalBackground={certifier.colors.buttons.buttonTertiary}
                          optionalTextColor={certifier
                            .colors.buttons.buttonTertiary_darkTheme ? "#fff" : "#000"}
                          textButton={`Saiba mais`}
                          desktopWidth="200px"
                          mobileWidth="100%"
                        />
                      </Link>
                    </div>
                  </div>
                ) : null}
              </div>
            </section>
          </div>,
          popupContainer
        )}
    </>
  );
}
