import axios from "axios";
import moment from "moment";
import "moment/locale/pt-br";
import { CryptoService } from "../cryptoService/crypto.service";
import * as Sentry from "@sentry/react";
import { certifier } from "../../mocks/certifier";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getAvaliationPage = async ({ studentInfos, courseAlias }) => {
  try {
    const post_data = await CryptoService(
      {
        sessionId: studentInfos.sessionId,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );
    const body = { courseAlias: courseAlias };
    const config = {
      headers: {
        auth: `${post_data}`,
      },
    };

    const responseCrypt = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/avaliationPage`,
      body,
      config
    );
    if (responseCrypt.data.code !== 200) {
      return { status: responseCrypt.data.code };
    }
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    return {
      data: response,
      status: responseCrypt.data.code,
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);
      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response ? err.response.data.code : 500 };
  }
};

export const getStartAvaliation = async (data) => {
  try {
    const avaliationType = data.avaliationType;
    const courseAlias = data.courseAlias;
    const sessionId = data.sessionId;
    const matriculationId = data.matriculationId;
    const disciplineId = data.disciplineId;
    const courseId = data.courseId;
    const post_data = await CryptoService(
      {
        sessionId: sessionId,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );
    const cryptoInfos = await CryptoService(
      {
        matriculationId: matriculationId,
        courseId: courseId,
        disciplineId: disciplineId,
        courseAlias: courseAlias,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );
    const body = { token: cryptoInfos };
    const config = {
      headers: {
        auth: `${post_data}`,
      },
    };

    const responseCrypt = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/start${capitalizeFirstLetter(
        avaliationType
      )}`,
      body,
      config
    );

    if (responseCrypt.data.code !== 200) {
      return { status: responseCrypt.data.code };
    }
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    return {
      data: response,
      status: responseCrypt.data.code,
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);
      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};

export const getViewCorrectionAvaliation = async (data) => {
  try {
    const post_data = await CryptoService(
      {
        sessionId: data.sessionId,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );
    const avaliationData = {
      avaliationId: data.avaliationId,
      exp: moment().add(2, "hours").unix(),
    };
    const cryptoInfos = await CryptoService(avaliationData, "encrypt-jwt");
    const body = { token: cryptoInfos };
    const config = {
      headers: {
        auth: `${post_data}`,
      },
    };
    const responseCrypt = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/viewCorrectionAvaliation`,
      body,
      config
    );
    if (responseCrypt.data.code !== 200) {
      return { status: responseCrypt.data.code };
    }
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    return {
      data: response,
      status: responseCrypt.data.code,
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);
      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};

export const getSendAvaliation = async (data) => {
  try {
    const sessionId = data.pageParams.sessionId;
    const avaliationData = {
      data: data.avaliationData,
      certifier,
      exp: moment().add(2, "hours").unix(),
    };
    const cryptoInfos = await CryptoService(avaliationData, "encrypt-jwt");
    const post_data = await CryptoService(
      {
        sessionId: sessionId,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );
    const body = { token: cryptoInfos };
    const config = {
      headers: {
        auth: `${post_data}`,
      },
    };
    const responseCrypt = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/finishStudentAvaliation`,
      body,
      config
    );
    if (responseCrypt.data.code !== 200) {
      return { status: responseCrypt.data.code };
    }
    return {
      data: responseCrypt.data,
      status: responseCrypt.data.code,
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);
      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};

// Start Avaliation from ClassPage
export const getStartAvaliationClassPage = async (data) => {
  try {
    const sessionId = data.sessionId;
    const disciplineId = data.disciplineId;
    const courseId = data.courseId;
    const post_data = await CryptoService(
      {
        sessionId,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );
    const cryptoInfos = await CryptoService(
      {
        courseId,
        disciplineAlias: disciplineId,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );
    const body = { token: cryptoInfos };
    const config = {
      headers: {
        auth: `${post_data}`,
      },
    };

    const responseCrypt = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/startAvaliationClassPage`,
      body,
      config
    );

    if (responseCrypt.data.code !== 200) {
      return { status: responseCrypt.data.code };
    }
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    return {
      data: response,
      status: responseCrypt.data.code,
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);
      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};
