import React from "react";
import { Link } from "react-router-dom";

import { HiCheckCircle, HiLockClosed, HiMiniPlay, HiMiniCheck } from "react-icons/hi2";
import { certifier } from "../../../mocks/certifier";

export default function CourseClassVideoCard({
  position,
  image,
  title,
  description,
  duration,
  status,
  classprogress,
  classConcluded,
  classId,
  courseAlias,
  type,
  disciplineId,
}) {
  const progressBar = {
    width: (String(classprogress) || "100") + "%",
  };

  return (
    <div className="w-full">
      <Link
        to={`${
          status === "unlock"
            ? `/course/${courseAlias}/${disciplineId}/${classId}`
            : "#"
        }`}
      >
        <section className="w-full flex flex-col">
          <div className="group my-3 mx-3 flex flex-col h-full transition-all duration-300 rounded-md cursor-pointer z-10 max-lg:mx-1.5 max-lg:my-1.5">
            <div className="w-full flex items-end relative">
              {progressBar && (
                <>
                  <div className="absolute w-full h-full rounded-md overflow-hidden flex flex-col justify-end items-end">
                    {classprogress !== 0 ? (
                      <div className="w-full h-1.5 bg-[#2a2d31]/70">
                        <div
                          className="h-full bg-themeColor"
                          style={progressBar}
                        />
                      </div>
                    ) : (
                      <div className="w-full h-1.5" />
                    )}
                  </div>
                </>
              )}
              {status === "unlock" ? (
                <div className="z-[1] opacity-0 group-hover:opacity-100 flex transition-all duration-200 absolute w-full h-full rounded-md justify-center items-center">
                  <div className="bg-[#3a3b41cc] h-10 w-10 rounded-full flex items-center justify-center text-[#fff]">
                    <HiMiniPlay className="w-4 h-4" />
                  </div>
                </div>
              ) : (
                <div className="z-[1] flex absolute  w-full h-full rounded-md justify-end items-start pt-3 pr-3">
                  <div className="bg-[#3a3b41cc] h-7 w-7 rounded-full flex items-center justify-center text-[#fff]">
                    <HiLockClosed className="w-3 h-3" />
                  </div>
                </div>
              )}
              {classConcluded && (
                <div className="z-[1] flex absolute  w-full h-full rounded-md justify-end items-start pt-3 pr-3">
                  <div className="bg-[#3a3b41cc] h-7 w-7 rounded-full flex items-center justify-center text-[#fff]">
                    <HiMiniCheck className="w-3 h-3" />
                  </div>
                </div>
              )}

              <img
                src={
                  image
                    ? image
                    : `/images/root/android-chrome-512x512.webp`
                }
                className={`w-full h-[150px] rounded-md object-cover max-lg:w-[67vw] max-lg:h-[36.75vw] ${
                  status === "lock" && "grayscale"
                }`}
                alt="Capa da aula"
              />
            </div>

            <div className="flex flex-col w-full h-full pt-3 gap-y-2">
              <div>
                <span className="text-base select-none leading-tight font-medium line-clamp-3">
                  {`${position + 1}. ${title}`}
                </span>
              </div>
              <div className="w-full text-xs text-textSecondary select-none leading-tight font-normal line-clamp-3">
                {description}
              </div>
            </div>
          </div>
        </section>
      </Link>
    </div>
  );
}
