import React, { useEffect, useState } from "react";
import "./newRecovery.css";
import { Link } from "react-router-dom";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import Input from "../../components/Input/input";
import { HiMiniArrowLeft } from "react-icons/hi2";
import GuestFooter from "../../components/Footers/GuestFooter";
import moment from "moment";
import "moment/locale/pt-br";
import { newRecoveryRequest } from "../../services/newRecovery/newRecovery.service";
import { newPassRequest } from "../../services/newPass/newPass.service";
import { CryptoService } from "../../services/cryptoService/crypto.service";
import { Toastify } from "../../components/Toastify/Toastify";
import verifyToken from "../../services/verifyToken/verifyToken";
import getStudentInfos from "../../services/getStudentInfos/getStudentInfos";
import { certifier } from "../../mocks/certifier";

const sign = require("jwt-encode");

function NewRecovery() {
  const [submitState, setSubmitState] = useState(false);

  const [recoveryData, setRecoveryData] = useState({
    token: "",
    password: "",
    confirmPassword: "",
  });

  const statusMessages = [
    {
      code: 404,
      message: "Usuário não encontrado.",
    },
    {
      code: 500,
      message: "Ocorreu um erro interno. Tente novamente em alguns minutos.",
    },
    {
      code: 5001,
      message: "Senha vazia.",
    },
    {
      code: 5002,
      message: "Senha insuficiente.",
    },
    {
      code: 5003,
      message: "As senhas não coincidem.",
    },
  ];

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    if (token) {
      setRecoveryData({ ...recoveryData, token });
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRecoveryData({ ...recoveryData, [name]: value });
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setSubmitState(true);

    if (recoveryData.password === "") {
      Toastify("error", statusMessages[2].message);
      setSubmitState(false);
      return;
    }
    if (recoveryData.password.length < 6 || recoveryData.password.length > 30) {
      Toastify("error", statusMessages[3].message);
      setSubmitState(false);
      return;
    }

    if (recoveryData.password !== recoveryData.confirmPassword) {
      Toastify("error", statusMessages[4].message);
      setSubmitState(false);
      return;
    }

    if (recoveryData.token !== "") {
      const post_data = sign(
        {
          token: recoveryData.token,
          pass: await CryptoService(recoveryData.password, "encrypt-jwt"),
          environment: process.env.REACT_APP_API_ENVIRONMENT,
          exp: moment().add(2, "hours").unix(),
          iat: moment().unix(),
        },
        process.env.REACT_APP_JWT_KEY
      );

      newRecoveryRequest(post_data)
        .then(async (res) => {
          if (res.status === 200) {
            Toastify("success", "Senha recuperada com sucesso!");
            const cryptoSession = await CryptoService(
              res.data,
              "encrypt-crypto"
            );
            localStorage.setItem("user", cryptoSession);
            setTimeout(() => {
              window.location.href = "/dashboard";
            }, 1000);
            return;
          } else {
            Toastify(
              "error",
              statusMessages.find((message) => message.code === res.status)
                ?.message ||
                "Ocorreu um erro interno. Tente novamente em alguns minutos."
            );
          }
        })
        .catch((err) => {
          Toastify(
            "error",
            statusMessages.find((message) => message.code === err.status)
              ?.message ||
              "Ocorreu um erro interno. Tente novamente em alguns minutos."
          );
        })
        .finally(() => {
          setSubmitState(false);
        });
    } else {
      // newPass api
      getStudentInfos()
        .then(async (localToken) => {
          if (localToken) {
            try {
              const sessionId = localToken.sessionId;

              const post_data = sign(
                {
                  sessionId,
                  pass: await CryptoService(
                    recoveryData.password,
                    "encrypt-jwt"
                  ),
                  environment: process.env.REACT_APP_API_ENVIRONMENT,
                  exp: moment().add(2, "hours").unix(),
                  iat: moment().unix(),
                },
                process.env.REACT_APP_JWT_KEY
              );

              newPassRequest(post_data)
                .then(async (res) => {
                  if (res.status === 200) {
                    Toastify("success", "Senha alterada com sucesso!");
                    localToken.toReplace = false;
                    const ciphertext = await CryptoService(
                      localToken,
                      "encrypt-crypto"
                    );
                    localStorage.setItem("user", ciphertext);
                    setTimeout(() => {
                      if (localToken.completed) {
                        window.location.href = "/dashboard";
                      } else {
                        window.location.href = "/user/basicInfos";
                      }
                    }, 1000);
                  } else {
                    Toastify(
                      "error",
                      statusMessages.find(
                        (message) => message.code === res.status
                      )?.message ||
                        "Ocorreu um erro interno. Tente novamente em alguns minutos."
                    );
                  }
                })
                .catch((err) => {
                  Toastify(
                    "error",
                    statusMessages.find(
                      (message) => message.code === err.status
                    )?.message ||
                      "Ocorreu um erro interno. Tente novamente em alguns minutos."
                  );
                })
                .finally(() => {
                  setSubmitState(false);
                });
            } catch (error) {
              setSubmitState(false);
              Toastify(
                "error",
                statusMessages.find((message) => message.code === error.status)
                  ?.message ||
                  "Ocorreu um erro interno. Tente novamente em alguns minutos."
              );
            }
          } else {
            setSubmitState(false);
            Toastify("error", "Usuário não logado.");
          }
        })
        .catch((error) => {
          console.error("Erro ao buscar informações do estudante:", error);
          setSubmitState(false);
          Toastify("error", "Erro ao obter informações do usuário.");
        });
    }
  }

  return (
    <main className="flex w-screen h-screen max-lg:h-full overflow-hidden bg-backgroundOne text-textPrimary">
      <div className="w-fit min-w-[25vw] flex flex-col relative max-lg:hidden">
        <div className="flex w-full items-center justify-center">
          <img
            src={`/images/logos/logo-text-on-${certifier.colors.basics.primary_darkTheme ? "light" : "dark"}.webp`}
            alt="Logo"
            className="absolute w-1/3 top-5 z-[2]"
          />
        </div>
        <div className="w-auto h-full relative overflow-hidden">
          <img
            src={`/images/WebPageLogos/imageLogin.webp`}
            alt="Imagem da Logo"
            className="w-full h-full object-cover pointer-events-none select-none"
          />
        </div>
      </div>

      <section className="w-full h-[100vh] flex flex-col overflow-y-auto justify-between items-center">
        <div className="hidden max-lg:flex w-full h-full relative">
          <img
            src="/images/WebPageLogos/imageLogin-mobile.webp"
            alt="Logo"
            className="w-full object-cover"
          />
          <div className="absolute bottom-0 w-full bg-backgroundOne h-3 rounded-t-xl" />
        </div>
        <section className="max-lg:w-full max-lg:px-[5vw] w-[45%] mt-[10%] flex flex-col max-lg:pb-16 items-start gap-y-10">
          <div className="flex flex-col items-start gap-5">
            <Link
              to="/login"
              className="text-themeColor font-normal flex items-center gap-x-1 text-sm link-type leading-none"
            >
              <HiMiniArrowLeft />
              <span>Voltar</span>
            </Link>
            <h1 className="font-semibold max-lg:text-[5vw] text-[2rem] text-left leading-none">
              Cadastre uma nova senha
            </h1>
            <span className="description-style leading-none">
              Digite sua nova senha e confirme a alteração.
            </span>
          </div>
          <form
            onSubmit={handleSubmit}
            className="flex flex-col gap-y-7 w-full"
          >
            <div className="flex flex-col gap-y-3 w-full">
              <label className="span-small-white">Nova senha</label>
              <Input
                type="password"
                name="password"
                value={recoveryData.password}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col gap-y-3 w-full">
              <label className="span-small-white">Confirme a senha</label>
              <Input
                type="password"
                name="confirmPassword"
                value={recoveryData.confirmPassword}
                onChange={handleChange}
              />
            </div>

            <div className="flex justify-center">
              <ButtonPrimary
                textButton="Salvar"
                desktopWidth="35%"
                mobileWidth="60%"
                disabled={submitState}
              />
            </div>
          </form>
        </section>
        <GuestFooter />
      </section>
    </main>
  );
}

export default verifyToken(NewRecovery);
