import React, { useEffect, useRef } from "react";
import { postPlayerMoment } from "../../services/playerService/player.service";

const PandaVideoPlayer = ({
  autoPlay,
  sessionId,
  disciplineId,
  classId,
  courseId,
  className,
  src,
  time,
  backgroundVideo,
}) => {
  let player;

  const containerRef = useRef(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://player.pandavideo.com.br/api.v2.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      let videoStatus = "";
      window.pandascripttag = window.pandascripttag || [];
      window.pandascripttag.push(function () {
        player = new window.PandaPlayer(`panda-${src.split("v=")[1]}`, {
          onReady: () => {
            setInterval(async () => {
              if (
                !backgroundVideo &&
                sessionId &&
                classId &&
                courseId &&
                disciplineId &&
                videoStatus === "playing"
              ) {
                postPlayerMoment({
                  sessionId: sessionId,
                  watchedSeconds: Math.floor(await player.getCurrentTime()),
                  totalSeconds: Math.floor(await player.getDuration()),
                  disciplineId: disciplineId,
                  classId: classId,
                  courseId: courseId,
                });
              }
            }, 5000);

            player.onEvent(function ({ message }) {
              if (message === "panda_pause") {
                videoStatus = "paused";
              } else if (message === "panda_play") {
                videoStatus = "playing";
              }
            });
          },
          onError: (event) => {
            console.error("Player onError", event);
          },
        });
      });
    };

    return () => {};
  }, []);

  useEffect(() => {
    const handleResize = () => {
      try {
        const containerWidth = containerRef.current.offsetWidth;
        const newHeight = (9 / 16) * containerWidth;
        containerRef.current.style.height = `${newHeight}px`;
      } catch (error) {
        console.error(`Player Resize Error - ${error.message}`);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      id="video-player-container"
      className={className ?? "w-full h-full"}
    >
      <iframe
        id={`panda-${src.split("v=")[1]}`}
        src={`${src}&preload=true&startTime=${time ?? "0"
          }&troubleshootDialog=false${autoPlay
            ? "&autoplay=true&muted=true&hideControlsOnStart=true&hideControls=true&restartAfterEnd=true&initDisabledControls=true"
            : ""
          }`}
        style={{
          border: "0",
          width: "100%",
          height: "100%",
        }}
      />
    </div>
  );
};

export default PandaVideoPlayer;
