import axios from "axios";
import moment from "moment";
import "moment/locale/pt-br";
import { CryptoService } from "../cryptoService/crypto.service";
import * as Sentry from "@sentry/react";

export const sendStudentTCC = async ({ TCCFile, data, studentInfos }) => {
  try {
    data.exp = moment().add(2, "hours").unix();

    const post_data = await CryptoService(
      {
        sessionId: studentInfos.sessionId,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );

    const cryptData = await CryptoService(data, "encrypt-jwt");

    const formData = new FormData();
    formData.append("file", TCCFile);
    formData.append("data", cryptData);

    const config = {
      headers: {
        auth: `${post_data}`,
      },
    };

    const response = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/sendStudentTcc`,
      formData,
      config
    );

    if (response.status !== 200) {
      return { status: response.status };
    }

    return { status: response.status, data: response.data };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);

      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response ? err.response.data.code : 500 };
  }
};

export const getStudentTCC = async ({ studentInfos, selectedCourseOption }) => {
  try {
    const post_data = await CryptoService(
      {
        sessionId: studentInfos.sessionId,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );

    const getTCCData = {
      courseId: selectedCourseOption.courseId,
      matriculationId: selectedCourseOption.matriculationId,
      exp: moment().add(2, "hours").unix(),
    };

    const cryptoInfos = await await CryptoService(getTCCData, "encrypt-jwt");

    const body = {
      token: cryptoInfos,
    };

    const config = {
      headers: {
        auth: `${post_data}`,
      },
    };

    const responseCrypt = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/getStudentTcc`,
      body,
      config
    );
    if (responseCrypt.data.code !== 200 && responseCrypt.data.code !== 207) {
      return { status: responseCrypt.data.code };
    }
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    return {
      data: response,
      status: responseCrypt.data.code,
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);

      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};

export const sendDispenseTCC = async ({ data, studentInfos }) => {
  try {
    const selectedCourseOption = data;
    const post_data = await CryptoService(
      {
        sessionId: studentInfos.sessionId,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );

    const getTCCData = {
      courseId: selectedCourseOption.courseId,
      matriculationId: selectedCourseOption.matriculationId,
      exp: moment().add(2, "hours").unix(),
    };

    const cryptoInfos = await CryptoService(getTCCData, "encrypt-jwt");

    const body = {
      token: cryptoInfos,
    };

    const config = {
      headers: {
        auth: `${post_data}`,
      },
    };

    const responseCrypt = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/dispenseTcc`,
      body,
      config
    );
    if (responseCrypt.data.code !== 200) {
      return { status: responseCrypt.data.code };
    }
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    return {
      data: response,
      status: responseCrypt.data.code,
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);

      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return {
      status: err.response && err.response.data ? err.response.data.code : 500,
    };
  }
};
