import React, { useEffect, useRef, useState } from "react";
import PandaVideoPlayer from "../VideoPlayers/PandaVideoPlayer";
import IframeVideoPlayer from "../VideoPlayers/IframeVideoPlayer";
import { HiArrowSmallLeft } from "react-icons/hi2";
import ClassVideoCard from "../ClassVideoCard/ClassVideoCard";
import { HiOutlineDocumentArrowDown } from "react-icons/hi2";
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";
import { HiPlay } from "react-icons/hi2";
import { Link, useParams } from "react-router-dom";
import { getNextClass } from "../../services/disciplineService/discipline.service";
import { Toastify } from "../Toastify/Toastify";
import axios from "axios";
import { debounce } from "lodash";
import { certifier } from "../../mocks/certifier";

export default function ClassContentSection({
  classContent,
  studentInfos,
  activeLoadingObj,
  // avaliationModal,
  handleSetAvaliationModal
}) {
  const scrollContainerRef = useRef(null);
  const courseAlias = useParams().courseAlias;
  const disciplineId = useParams().disciplineId;
  const classId = useParams().classId;
  const [activeContent, setActiveContent] = useState("player");
  const [activeContentName, setActiveContentName] = useState("Videoaulas");
  // const [fixedMobilePlayerHeight, setFixedMobilePlayerHeight] = useState(0);
  const contentType = [
    { name: "Videoaulas", identifier: ["player"] },
    { name: "Materiais", identifier: ["ebook", "slide", "map"] }
  ];
  const containerRef = useRef(null);
  const [playerHeight, setPlayerHeight] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowWidth(window.innerWidth);
      const player = document.getElementById("playerVideo");
      if (player) setPlayerHeight(player.offsetHeight);
      if (containerRef && containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const newHeight = (9 / 16) * containerWidth;
        containerRef.current.style.height = `${newHeight}px`;
      }
    }, 100);

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      handleResize.cancel();
    };
  }, [classContent]);

  useEffect(() => {
    const initialHandleResize = debounce(() => {
      setWindowWidth(window.innerWidth);
      const player = document.getElementById("playerVideo");
      if (player) setPlayerHeight(player.offsetHeight);
      if (containerRef && containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const newHeight = (9 / 16) * containerWidth;
        containerRef.current.style.height = `${newHeight}px`;
      }
    }, 100);

    initialHandleResize();
  }, []);

  function handleActiveContent(content) {
    let index = 0;
    let scrollToElement = document.getElementById(`${content}-${index}`);

    while (!scrollToElement && index < 50) {
      index++;
      scrollToElement = document.getElementById(`${content}-${index}`);
    }

    if (scrollToElement && scrollContainerRef && scrollContainerRef.current) {
      if (windowWidth <= 1024) {
        const hiddenMobileDisciplineTop = document.getElementById(
          "hiddenMobileDisciplineTop"
        );

        if (hiddenMobileDisciplineTop && hiddenMobileDisciplineTop.style) {
          window.scrollTo({
            top:
              scrollToElement.offsetTop +
              playerHeight -
              Number(hiddenMobileDisciplineTop.style.height.replace("px", "")),
            behavior: "smooth"
          });
        }
      } else {
        scrollContainerRef.current.scrollTo({
          top: scrollToElement.offsetTop - 12,
          behavior: "smooth"
        });
      }
    } else if (scrollToElement) {
      window.scrollTo({
        top: scrollToElement.offsetTop - 12,
        behavior: "smooth"
      });
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth > 1024) {
        const container = scrollContainerRef.current;
        if (!container || !classContent.classes) return;

        const offsets = classContent.classes.map((content, index) => ({
          offsetTop:
            document.getElementById(`${content.type}-${index}`)?.offsetTop +
              64 ?? 0,
          type: content.type
        }));

        const currentOffset = container.scrollTop;
        const closest = offsets.reduce((prev, curr) => {
          if (
            curr.offsetTop >= currentOffset &&
            (!prev || curr.offsetTop < prev.offsetTop)
          ) {
            return curr;
          }
          return prev;
        }, null);
        if (closest) {
          setActiveContent(closest.type);
        }
      }
    };

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
      return () => scrollContainer.removeEventListener("scroll", handleScroll);
    }
  }, [classContent]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth <= 1024) {
        const offsets = classContent.classes.map((content, index) => ({
          offsetTop:
            document.getElementById(`${content.type}-${index}`)?.offsetTop ?? 0,
          type: content.type,
          name: content.name
        }));

        const hiddenMobileDisciplineTop = document.getElementById(
          "hiddenMobileDisciplineTop"
        );
        const player = document.getElementById("playerVideo");
        const playerHeight = player ? player.offsetHeight : 0;
        const closest = offsets.reduce((prev, curr) => {
          if (hiddenMobileDisciplineTop && hiddenMobileDisciplineTop.style) {
            if (
              curr.offsetTop >=
                window.scrollY -
                  window.innerHeight +
                  playerHeight +
                  Number(
                    hiddenMobileDisciplineTop.style.height.replace("px", "")
                  ) *
                    5 &&
              (!prev || curr.offsetTop < prev.offsetTop)
            ) {
              return curr;
            }
          }
          return prev;
        }, null);
        if (closest) {
          setActiveContent(closest.type);
          setActiveContentName(String(closest.name).split(" ")[0]);
        }
      }
    };

    handleScroll();
    window.addEventListener("scroll", () => {
      handleScroll();
    });
    return () => {
      window.removeEventListener("scroll", () => {
        handleScroll();
      });
    };
  }, [classContent]);

  const handleNextClassClick = async () => {
    activeLoadingObj();
    await getNextClass({
      disciplineId: disciplineId,
      sessionId: studentInfos.sessionId,
      classId: classId,
      courseAlias: courseAlias
    })
      .then(async (response) => {
        if (
          response.status === 200 &&
          response.data.nextDisciplineId !== undefined &&
          response.data.classId !== undefined
        ) {
          window.location.href = `/course/${courseAlias}/${response.data.nextDisciplineId}/${response.data.classId}`;
        } else {
          Toastify("error", "Não foi possível obter a próxima aula.");
          window.location.reload();
          activeLoadingObj();
        }
      })
      .catch((error) => {
        Toastify("error", "Erro ao buscar informações da próxima aula.");
        activeLoadingObj();
      });
  };

  const downloadFile = async (data) => {
    try {
      const url = data.url;
      const options = {
        responseType: "blob"
      };
      const response = await axios.get(url, options);
      const tempLink = document.createElement("a");
      tempLink.href = URL.createObjectURL(response.data);
      tempLink.setAttribute(
        "download",
        `${String(data.name)}.${String(data.url.split(`.`).pop())}`
      );
      tempLink.click();
    } catch (error) {
      Toastify(
        "error",
        "Erro ao baixar o conteúdo. Tente novamente mais tarde."
      );
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const disciplineListTop = document.getElementById("disciplineListTop");
      const hiddenMobileDisciplineTop = document.getElementById(
        "hiddenMobileDisciplineTop"
      );
      const player = document.getElementById("playerVideo");
      if (!disciplineListTop || !player) return;
      const playerHeight = player.offsetHeight;
      const disciplineListTopOffsetTop = disciplineListTop.offsetTop;
      const disciplineListTopHeight = disciplineListTop.offsetHeight;
      if (window.innerWidth <= 1024) {
        if (
          (disciplineListTop.style.position !== "fixed" &&
            window.scrollY > disciplineListTopOffsetTop - playerHeight) ||
          (disciplineListTop.style.position === "fixed" &&
            window.scrollY > hiddenMobileDisciplineTop.offsetTop - playerHeight)
        ) {
          disciplineListTop.style.position = "fixed";
          disciplineListTop.style.top = `${playerHeight}px`;
          disciplineListTop.style.padding = "8px 5vw 0 5vw";

          if (hiddenMobileDisciplineTop)
            hiddenMobileDisciplineTop.style.height = `${disciplineListTopHeight}px`;
        } else {
          disciplineListTop.style.position = "static";
          disciplineListTop.style.top = "0";
          disciplineListTop.style.padding = "8px 0 0 0";

          if (hiddenMobileDisciplineTop)
            hiddenMobileDisciplineTop.style.height = `0px`;
        }
      }
    };

    handleScroll();
    window.addEventListener("scroll", () => {
      handleScroll();
    });

    window.addEventListener("resize", () => {
      handleScroll();
    });

    return () => {
      window.removeEventListener("scroll", () => {
        handleScroll();
      });
      window.removeEventListener("resize", () => {
        handleScroll();
      });
    };
  }, [playerHeight]);

  if (classContent.currentClass)
    return (
      <section className="flex gap-6 w-full h-full max-lg:flex-col">
        <section className="w-[75%] max-lg:w-full h-full max-lg:h-[auto]">
          <section
            id="playerVideo"
            className="overflow-hidden w-full rounded-[0.5rem] max-lg:rounded-none z-[1] max-lg:border-b-[8px] max-lg:border-black max-lg:fixed bg-backgroundOne">
            {classContent.currentClass.type === "player" ? (
              String(classContent.currentClass.src).includes("panda") ? (
                <PandaVideoPlayer
                  sessionId={studentInfos.sessionId}
                  disciplineId={disciplineId}
                  classId={classId}
                  courseId={classContent.courseId}
                  src={classContent.currentClass.src}
                  time={classContent.currentClass.viewedTime}
                />
              ) : String(classContent.currentClass.src).includes("scorm") ? (
                <div
                  ref={containerRef}
                  style={{ width: "100%", overflow: "hidden" }}>
                  <iframe
                    title="scorm-class"
                    onLoad={() => {
                      const player = document.getElementById("playerVideo");
                      if (player) setPlayerHeight(player.offsetHeight);
                      // const disciplineListTop =
                      //   document.getElementById("disciplineListTop");
                      // if (disciplineListTop)
                      // setDisciplineListTopHeight(
                      // disciplineListTop.offsetHeight
                      // );
                    }}
                    src={classContent.currentClass.src}
                    style={{ border: "0", width: "100%", height: "100%" }}
                  />
                </div>
              ) : (
                <IframeVideoPlayer
                  sessionId={studentInfos.sessionId}
                  disciplineId={disciplineId}
                  classId={classId}
                  courseId={classContent.courseId}
                  src={
                    String(classContent.currentClass.name).includes(
                      "Avaliação da Unidade"
                    ) ? certifier.generalSettings.foldername === "celsolisboa"
                      ? "https://storage.googleapis.com/video-aula-libano/MKT/TUTORIAIS/Celso%20Lisboa/AVALIAÇÃO%20ON-LINE.mp4" : "https://storage.googleapis.com/video-aula-libano/MKT/TUTORIAIS/Faculdade%20Libano/VI%CC%81DEO%20AVALIAC%CC%A7A%CC%83O.mp4"
                      : classContent.currentClass.src
                  }
                  time={ String(classContent.currentClass.name).includes(
                    "Avaliação da Unidade"
                  ) ? 0 : classContent.currentClass.viewedTime}
                />
              )
            ) : classContent.currentClass.type === "map" ? (
              <img
                onLoad={() => {
                  const player = document.getElementById("playerVideo");
                  if (player) setPlayerHeight(player.offsetHeight);
                  // const disciplineListTop =
                  //   document.getElementById("disciplineListTop");
                  // if (disciplineListTop)
                  // setDisciplineListTopHeight(disciplineListTop.offsetHeight);
                }}
                alt="Mapa Mental"
                className="w-full h-full"
                src={classContent.currentClass.link}
              />
            ) : (
              <iframe
                onLoad={() => {
                  const player = document.getElementById("playerVideo");
                  if (player) setPlayerHeight(player.offsetHeight);
                  // const disciplineListTop =
                  //   document.getElementById("disciplineListTop");
                  // if (disciplineListTop)
                  // setDisciplineListTopHeight(disciplineListTop.offsetHeight);
                }}
                title="Conteúdo da Aula"
                ref={containerRef}
                className="w-full h-full"
                src={classContent.currentClass.link}
              />
            )}
          </section>
          <div
            style={{ paddingTop: windowWidth <= 1024 ? playerHeight + 12 : 24 }}
            className="flex justify-between pt-3 max-lg:p-[5vw] max-lg:pb-0 max-lg:flex-col gap-7">
            <div className="flex flex-col w-[60%] h-full gap-y-2 max-lg:w-full">
              <h1 className="text-base leading-tight font-medium">
                {classContent.currentClass.name}
              </h1>
              <span className="text-xs text-textSecondary leading-tight font-normal max-lg:line-clamp-3">
                {classContent.currentClass.description}
              </span>
            </div>
            <div className="w-[40%] flex justify-end max-lg:w-full max-lg:justify-center max-lg:flex-col max-lg:gap-5">
              <div className="flex gap-x-3 h-fit w-full max-lg:justify-start justify-end">
                {classContent.currentClass.type === "player" ||
                // classContent.currentClass.type === "avaliation" ? null : (
                String(classContent.currentClass.name).includes(
                  "Avaliação da Unidade"
                ) ? null : (
                  <ButtonPrimary
                    optionalBackground={certifier.colors.buttons.buttonTertiary}
                    optionalTextColor={
                      certifier.colors.buttons.buttonTertiary_darkTheme
                        ? "#fff"
                        : "#000"
                    }
                    textButton="Baixar"
                    desktopWidth="50%"
                    mobileWidth="48%"
                    optionalMovingIcon={<HiOutlineDocumentArrowDown />}
                    verifyOnClick={() =>
                      downloadFile({
                        url: classContent.currentClass.link,
                        name: classContent.currentClass.name
                      })
                    }
                  />
                )}
                {/* {classContent.currentClass.type === "avaliation" && ( */}
                {String(classContent.currentClass.name).includes(
                  "Avaliação da Unidade"
                ) &&
                  classContent.avaliationConfig.reTry.value -
                    classContent.avaliationConfig.tries >
                    0 &&
                  !classContent.avaliationConfig.dispense &&
                  classContent.avaliationConfig.situation !== "Indisponível" &&
                  !classContent.avaliationConfig.blockAvaliation && (
                    <ButtonPrimary
                      optionalBackground={
                        certifier.colors.buttons.buttonTertiary
                      }
                      optionalTextColor={
                        certifier.colors.buttons.buttonTertiary_darkTheme
                          ? "#fff"
                          : "#000"
                      }
                      textButton="Realizar avaliação"
                      desktopWidth="50%"
                      mobileWidth="48%"
                      optionalMovingIcon={<HiOutlineDocumentArrowDown />}
                      verifyOnClick={() => {
                        handleSetAvaliationModal({
                          situation: classContent.avaliationConfig.situation,
                          duration:
                            classContent.avaliationConfig.avaliationTime.value,
                          retries: classContent.avaliationConfig.reTry.value,
                          tries: classContent.avaliationConfig.tries,
                          fake: classContent.avaliationConfig.fake
                        });
                      }}
                    />
                  )}
                {classContent.existsNextClass && (
                  <ButtonPrimary
                    optionalBackground={certifier.colors.buttons.buttonTertiary}
                    optionalTextColor={
                      certifier.colors.buttons.buttonTertiary_darkTheme
                        ? "#fff"
                        : "#000"
                    }
                    textButton={
                      // classContent.currentClass.type === "avaliation"
                      String(classContent.currentClass.name).includes(
                        "Avaliação da Unidade"
                      )
                        ? "Próximo módulo"
                        : "Próxima aula"
                    }
                    desktopWidth="50%"
                    mobileWidth="48%"
                    optionalMovingIcon={<HiPlay />}
                    verifyOnClick={handleNextClassClick}
                  />
                )}
              </div>
            </div>
          </div>
        </section>

        <section className="w-[25%] max-lg:w-full h-hull max-lg:px-[5vw] max-lg:h-[auto] flex flex-col">
          <div id="hiddenMobileDisciplineTop" />
          <section
            id="disciplineListTop"
            className="flex flex-col bg-backgroundThree rounded-t-[0.5rem] w-full px-4 pt-5 gap-y-10 max-lg:bg-backgroundOne max-lg:px-0 max-lg:left-0 z-[10]">
            <div className="flex items-center justify-start max-lg:hidden">
              <div className="flex items-center w-fit pr-4 text-2xl h-full">
                <Link to={`/course/${courseAlias}`}>
                  <HiArrowSmallLeft />
                </Link>
              </div>
              <div className="flex flex-col gap-y-1 pr-2">
                <span className="font-semibold text-sm">
                  {/* Módulo {String(classContent.module).padStart(2, "0")} */}
                  {String(classContent.disciplineName)}
                </span>
                <span className="text-textSecondary text-xs">
                  {classContent.name}
                </span>
              </div>
            </div>

            <div id="classTypes" className="flex w-full justify-around">
              {contentType.map((content, index) => (
                <button
                  onClick={() => handleActiveContent(content.identifier[0])}
                  className="w-1/3 flex flex-col items-center justify-center rounded-xl"
                  key={index}>
                  <span className="text-[0.75rem] text-textPrimary font-medium leading-tight overflow-hidden overflow-ellipsis line-clamp-3 select-none pb-3">
                    {content.name}
                  </span>
                  <div
                    className={`${
                      (content.identifier.includes(activeContent) &&
                        !(
                          activeContent === "player" &&
                          (activeContentName === "Avaliação" ||
                            activeContentName === "Recomendação")
                        )) ||
                      (content.identifier[0] === "slide" &&
                        (activeContentName === "Avaliação" ||
                          activeContentName === "Recomendação"))
                        ? "w-full"
                        : "w-0"
                    } h-[2px] bg-themeColor transition-all duration-300`}
                  />
                </button>
              ))}
            </div>
          </section>
          <section
            className="relative overflow-hidden w-full max-lg:mb-[60px]"
            style={{
              height:
                windowWidth > 1024
                  ? // ? `${playerHeight - disciplineListTopHeight}px`
                    `${playerHeight}px`
                  : "100%"
            }}>
            <div className="absolute pointer-events-none bottom-0 h-16 w-full bg-gradient-to-t from-backgroundOne-via-72 opacity-100 z-[1] max-lg:hidden" />
            <div
              ref={scrollContainerRef}
              className="flex flex-col h-full overflow-y-scroll custom-dark-scroll pr-2 pl-4 mt-4 pb-8 mx-2 w-[calc(100%-1rem)] gap-y-1 max-lg:overflow-visible max-lg:max-h-full max-lg:pb-0 max-lg:pl-0 max-lg:pr-0 max-lg:mx-0">
              {classContent.classes &&
                classContent.classes.map((content, index) => (
                  <ClassVideoCard
                    key={index}
                    index={index}
                    content={content}
                    disciplineId={disciplineId}
                    courseAlias={courseAlias}
                  />
                ))}
            </div>
          </section>
        </section>
      </section>
    );
}
