import React, { useEffect, useState } from "react";
import verifyAuth from "../../services/verifyAuth/verifyAuth";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import ClassContentSection from "../../components/ClassContentSection/ClassContentSection";
import { Toastify } from "../../components/Toastify/Toastify";
import { useLocation, useParams } from "react-router-dom";
import getStudentInfos from "../../services/getStudentInfos/getStudentInfos";
import { getDisciplineAndClasses } from "../../services/disciplineService/discipline.service";
import LoadingSection from "../../components/LoadingElements/LoadingSection";
import BlockquoteModal from "../../components/Modals/BlockquoteModal";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import { CryptoService } from "../../services/cryptoService/crypto.service";
import { getStartAvaliationClassPage } from "../../services/avaliationServices/avaliation.service";
import MobileNavigationBar from "../../components/MobileNavigationBar/MobileNavigationBar";
import { certifier } from "../../mocks/certifier";

function ClassPage() {
  const location = useLocation();
  const [classContent, setClassContent] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const classId = useParams().classId;
  const disciplineId = useParams().disciplineId;
  const courseAlias = useParams().courseAlias;
  const [studentInfos, setStudentInfos] = useState(undefined);
  const [avaliationModal, setAvaliationModal] = useState(undefined);

  useEffect(() => {
    const fetchClassPageInfo = async () => {
      setIsLoading(true);
      try {
        const student = await getStudentInfos();
        // getDisciplineAndClasses --- START
        const arrayDiscipline = await getDisciplineAndClasses({
          sessionId: student.sessionId,
          classId: classId,
          courseAlias: courseAlias,
          disciplineId: disciplineId,
        });
        if (arrayDiscipline.status !== 200) {
          Toastify("error", "Erro ao buscar informações de disciplina.");
          console.error(
            "Erro ao buscar informações de disciplina - 1:",
            arrayDiscipline.status
          );
        }
        // getDisciplineAndClasses --- END
        try {
          setClassContent(arrayDiscipline.data);
        } catch {
          Toastify("error", "Erro ao processar itens da disciplina: #01");
        }
      } catch (error) {
        Toastify("error", "Erro generalizado ao buscar disciplina.");
        window.location.href = "/";
      } finally {
        setIsLoading(false);
      }
    };
    fetchClassPageInfo();
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    getStudentInfos()
      .then(async (response) => {
        setStudentInfos(response);
      })
      .catch((error) => {
        Toastify("error", "Erro ao buscar informações do aluno.");
      });
  }, [location, classId]);

  const handleActiveLoadingObj = () => {
    setIsLoading(!isLoading);
  };

  const handleStartAvaliation = async () => {
    try {
      setIsLoading(true);
      const avaliationData = {
        sessionId: studentInfos.sessionId,
        courseId: classContent.courseId,
        disciplineId,
      };
      const avaliationResponse = await getStartAvaliationClassPage(
        avaliationData
      );
      if (avaliationResponse.status === 200) {
        const avaliationData = {
          sessionId: studentInfos.sessionId,
          matriculationId: classContent.matriculationId,
          courseId: classContent.courseId,
          courseAlias: courseAlias,
          disciplineId: classContent.disciplineMongoId,
          avaliationType: "avo",
        };
        const cryptParam = await CryptoService(avaliationData, "encrypt-jwt");
        window.location.href = `/avaliation?key=${encodeURIComponent(
          String(cryptParam)
        )}`;
        setIsLoading(false);
      } else {
        setIsLoading(false);
        Toastify("error", "Erro ao iniciar avaliação.");
      }
    } catch (err) {
      setIsLoading(false);
      Toastify("error", "Erro ao iniciar avaliação. #2");
    }
  };

  const handleDirectAvaliation = async (item) => {
    const avaliationData = {
      sessionId: studentInfos.sessionId,
      avaliationId: item.avaliationId,
      isViewAvaliation: true,
    };
    const cryptParam = await CryptoService(avaliationData, "encrypt-jwt");
    window.location.href = `/avaliation?key=${encodeURIComponent(
      String(cryptParam)
    )}`;
  };

  return (
    <main className="flex flex-col w-full overflow-x-hidden bg-backgroundOne items-center text-textPrimary min-h-screen">
      <HeaderBar
        hiddenHeaderOnLG
        actualPage="Aulas"
        courseNavigation={false}
        mobileBackPage={`/course/${courseAlias}`}
        disableBlurBackground
      />
      <MobileNavigationBar actualPage="Aulas" />
      <LoadingSection isLoading={isLoading} hideScroll />
      <div
        className="w-full px-14 max-lg:px-0"
      >
        <div
          className="flex gap-10 w-full h-full pb-9 max-lg:pb-0"
        >
          {classContent && (
            <ClassContentSection
              studentInfos={studentInfos}
              classContent={classContent}
              activeLoadingObj={handleActiveLoadingObj}
              handleSetAvaliationModal={setAvaliationModal}
            />
          )}
        </div>
      </div>
      {avaliationModal && (
        <BlockquoteModal
          text_top="Realizar avaliação"
          text_blockquote={
            avaliationModal.situation !== "Em andamento"
              ? `A prova terá uma duração de ${String(
                  avaliationModal.duration / 60
                ).replace(".", ",")} hora${
                  avaliationModal.duration / 60 >= 2 ? "s" : ""
                }. Além disso, você terá ${String(
                  avaliationModal.retries - avaliationModal.tries
                )} tentativa${
                  avaliationModal.retries >= 2 ? "(s)" : ""
                } para realizar a avaliação. Está pronto para iniciar?`
              : "Você tem certeza de que deseja dar continuidade a esta avaliação?"
          }
          outsideClick={() => setAvaliationModal(undefined)}
          buttonsLeft={
            (avaliationModal.situation === "Reprovado" ||
              avaliationModal.situation === "Aprovado") &&
            !avaliationModal.fake
              ? [
                  <ButtonPrimary
                    disableHorizontalMovement
                    optionalHoverBackground="#FFFFFF08"
                    optionalBackground="#00000000"
                    textButton="Ver avaliação"
                    desktopWidth="160px"
                    mobileWidth="100%"
                    verifyOnClick={() =>
                      handleDirectAvaliation(avaliationModal)
                    }
                  />,
                ]
              : []
          }
          buttonsRight={
            ((avaliationModal.situation === "Reprovado" ||
              avaliationModal.situation === "Aprovado") &&
              avaliationModal.tries < avaliationModal.retries) ||
            avaliationModal.situation === "Disponível" ||
            avaliationModal.situation === "Em andamento"
              ? [
                  <ButtonPrimary
                    verifyOnClick={() => setAvaliationModal(undefined)}
                    disableHorizontalMovement
                    optionalHoverBackground="#FFFFFF08"
                    optionalBackground="#00000000"
                    textButton="Cancelar"
                    optionalTextColor={certifier.colors.text.textPrimary}
                    desktopWidth="160px"
                    mobileWidth="100%"
                  />,
                  <ButtonPrimary
                    verifyOnClick={handleStartAvaliation}
                    textButton={`${
                      avaliationModal.situation === "Em andamento"
                        ? "Continuar"
                        : "Começar"
                    }`}
                    desktopWidth="160px"
                    mobileWidth="100%"
                  />,
                ]
              : [
                  <ButtonPrimary
                    verifyOnClick={() => setAvaliationModal(undefined)}
                    disableHorizontalMovement
                    textButton="Voltar"
                    desktopWidth="160px"
                    mobileWidth="100%"
                  />,
                ]
          }
        />
      )}
    </main>
  );
}

export default verifyAuth(ClassPage);
