import moment from "moment";
import { FaFacebook, FaInstagram, FaYoutube, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";
import { certifier } from "../../mocks/certifier";

export default function InternalFooter() {
  const mainPhrase =
    `“Porventura não se converterá o ${certifier.generalSettings.shortName}, num breve momento, em campo fértil? E o campo fértil não se reputará por um bosque?”\n- Isaías 29:17`;
  const bottomPhrase = `©️ ${moment().format(
    "YYYY"
  )} ${certifier.generalSettings.shortName} | Todos os direitos reservados`;

  const socialNetworks = [
    {
      alt: "Facebook",
      icon: <FaFacebook className="text-[1.25rem]" />,
      redirectTo: `${certifier.generalSettings.name}`,
    },
    {
      alt: "Instagram",
      icon: <FaInstagram className="text-[1.25rem]" />,
      redirectTo: `${certifier.generalSettings.socialMedia.instagram}`,
    },
    {
      alt: "Youtube",
      icon: <FaYoutube className="text-[1.25rem]" />,
      redirectTo: `${certifier.generalSettings.socialMedia.youtube}`,
    },
    {
      alt: "Linkedin",
      icon: <FaLinkedin className="text-[1.25rem]" />,
      redirectTo: `${certifier.generalSettings.socialMedia.linkedin}`,
    },
  ];

  const footerColumns = [
    {
      title: "Explorar",
      content: [
        {
          title: "Aulas",
          url: "/",
        },
        {
          title: "Cursos",
          url: "/studentCourses",
        },
        {
          title: "Perfil",
          url: "/user/basicInfos",
        },
      ],
    },
    {
      title: "Provas",
      content: [
        {
          title: "Avaliações",
          url: "/avaliations",
        },
        {
          title: "TCC",
          url: "/tcc",
        },
      ],
    },
    {
      title: "Documentos",
      content: [
        {
          title: "Declarações",
          url: "/user/declarations",
        },
        {
          title: "Documentos",
          url: "/user/documents",
        },
        {
          title: "Contratos",
          url: "/user/contracts",
        },
        {
          title: "Certificados",
          url: "/user/certificates",
        },
      ],
    },
    {
      title: "Suporte",
      content: [
        {
          title: "WhatsApp",
          url: "/support",
        },
        {
          title: "E-mail",
          url: `${certifier.generalSettings.emailSupport}`,
        },
        {
          title: "Termo de Uso",
          url: "/terms-of-use",
        },
        {
          title: "Política de Privacidade",
          url: "/privacy-policy",
        },
      ],
    },
  ];

  return (
    <footer className="flex flex-col w-full justify-center items-center bg-backgroundOne">
      <div className="w-full  border-t border-white/10 max-w-[1170px]" />
      <div className="flex flex-col w-full max-lg:px-[5vw] max-w-[1170px]">
        <div className="col-span-2 flex-col gap-4 hidden max-w-[364px] py-6 max-lg:flex">
          <div className="flex h-full gap-y-4">
            <img
              src={`/images/logos/logo-${certifier.colors.basics.primary_darkTheme ? "light" : "dark"}.webp`}
              alt="Logo"
              className="w-fit h-[40px]"
            />
          </div>
          <div className="text-sm">{mainPhrase}</div>
          <div className="flex items-center gap-x-1">
            {socialNetworks.map((network, index) => (
              <Link
                to={network.redirectTo}
                key={index}
                className="flex items-center justify-center w-10 h-10 text-textSecondary"
              >
                {network.icon}
              </Link>
            ))}
          </div>
        </div>
        <div className="grid grid-cols-6 py-12 gap-[2rem] max-lg:pt-6 max-lg:grid-cols-4 max-mc:grid-cols-2">
          <div className="col-span-2 flex flex-col gap-4 max-lg:hidden">
            <div className="flex h-fit gap-y-4">
              <img
                src={`/images/logos/logo-${certifier.colors.basics.primary_darkTheme ? "light" : "dark"}.webp`}
                alt="Logo"
                className="w-fit h-[28px]"
              />
            </div>
            <div className="text-sm text-textSecondary">{mainPhrase}</div>
            <div className="flex items-center gap-x-6">
              {socialNetworks.map((network, index) => (
                <Link
                  to={network.redirectTo}
                  key={index}
                  className="flex text-[1.5rem] text-textSecondary transition-all duration-300 hover-text-textPrimary"
                >
                  {network.icon}
                </Link>
              ))}
            </div>
          </div>
          {footerColumns.map((colummnItem, index) => (
            <div key={`1-${index}`} className="flex flex-col gap-y-5">
              <div className="text-sm text-textPrimary font-semibold">
                {colummnItem.title}
              </div>
              <div key={`1-${index}`} className="flex flex-col gap-y-4">
                {colummnItem.content.map((item, index) => (
                  <Link
                    to={item.url}
                    key={index}
                    className="text-sm transition-all duration-300 text-textSecondary hover-text-textPrimary"
                  >
                    {item.title}
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="flex w-full pb-6 gap-10 text-textSecondary text-sm">
          {bottomPhrase}
        </div>
      </div>
    </footer>
  );
}
